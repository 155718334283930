// 注册加载页面
import React, { useMemo } from 'react'
import styles from './register-loading-page.module.scss'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'

interface Props {}

const RegisterLoadingPage: React.FC<Props> = () => {
  const { channelValue } = useSelector((state: RootState) => state.channel)
  const loadingGif = useMemo(() => {
    switch (true) {
      case channelValue.includes('shallowBlue'):
        return <img src={require('@/assets/imgs/gif/loading2.gif')} alt="" />
      case channelValue.includes('huanbeitheme'):
        return <img src={require('@/assets/imgs/gif/loading3.gif')} alt="" />
      default:
        return <img src={require('@/assets/imgs/gif/loading.gif')} alt="" />
    }
  }, [channelValue])
  return (
    <div className={styles.registerLoadingPage}>
      <div className={styles.hengbeiLoading} >
        <div className={styles.loadingBox}>
          {/* <img src={require('@/assets/imgs/gif/loading.gif')} alt="" /> */}
          {loadingGif}
          <span>正在评估您的借款申请...</span>
        </div>
      </div>
    </div>
  )
}

export default RegisterLoadingPage
