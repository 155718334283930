/* eslint-disable no-shadow */
// 首页相关接口
import { api } from '@/utils/axios'
import { AuthResponese, PartnerProtocolList, ProductMatchInfo, Protocols, UserInfo } from '@/types/apis/user'
import { ApiResponse, EntranceType } from '@/types/apis'
import { ApplyLimit, ApplyStatus, AssetSituation, BumpStatus, CarProperty, Credit, CreditInvestigation, Gender, HouseProperty, HousingFund, Insurance, Job, MonthlyProfit, PayoffForm, RematchResult, SesameCredit, SocialSecurity, SubmitStatus, WorkDuration } from '@/constants/product-enum'
import { APPH5, ForceDistributeStatus, OneStageStatus, SignFieldFlag, UnionLogin } from '@/constants/user'

/** 表单字段 */
interface FormFields {

  /**
   * 真实姓名
   */
  realName?: string | null;

  /**
   * 身份证号
   */
  identity?: string | null;

  /**
   * 年龄
   */
  age?: number | null;

  /**
   * 性别，1-男、2-女
   */
  gender?: Gender | null;

  /**
   * 工作城市
   */
  workCity?: string | null;

  /**
   * 工作城市地区编码
   */
  workCityCode?: string | null;

  /**
   * 职业身份
   */
  job?: Job | null;

  /**
   * 工作年限
   */
  workDuration?: WorkDuration | null;

  /**
   * 工资发放
   */
  payoffForm?: PayoffForm | null;

  /**
   * 月收入
   */
  monthlyProfit?: MonthlyProfit | null;

  /**
   * 芝麻信用
   */
  sesameCredit?: SesameCredit | null;

  /**
   * 申请额度
   */
  applyLimit?: ApplyLimit | null;

  /**
   * 房产
   */
  houseProperty?: HouseProperty | null;

  /**
   * 车产
   */
  carProperty?: CarProperty | null;

  /**
   * 社保缴纳
   */
  socialSecurity?: SocialSecurity | null;

  /**
   * 公积金
   */
  housingFund?: HousingFund | null;

  /**
   * 保险保单
   */
  insurance?: Insurance | null;

  /**
   * 征信情况
   */
  creditInvestigation?: CreditInvestigation | null;
}

/** 保存客户信息（APP、H5都会调用） */
export interface SaveParams extends FormFields {

  /**
   * 用户渠道 code
   */
  adChannelCode: string;

  /**
   * 资产情况
   * H5 不再使用，但是 APP 还在使用
   */
  assetSituation?: AssetSituation[] | null;

  /**
   * 征信情况（详细版）
   * H5 不再使用，但是 APP 还在使用
   */
  credit?: Credit[] | null;

  /**
   * 是否华为手机
   */
  isHuaWeiPhone?: boolean | null;
}

/** 临时保存客户信息 */
export interface TempSaveParams extends FormFields { }

/** 用户直接匹配参数 */
export interface DirectMatchInfo {
  adChannelCode: string,
  directMatchType: number
}

/** 人脸识别返回参数 */
interface FaceVerifyResponse{

  /** 实人认证唯一标识 */
  certifyId: string,

  /** Web认证URL */
  certifyUrl: string
}

interface LoginInfo {
  uid: string,
  realName: string,
  mobile: string,
  loginAt:string
}

/** 获取用户表单流程中途推出后的信息获取 */
export interface GetProductInfoWidthStatus extends ProductMatchInfo {

  /** 是否提交表单，0-未提交，1-提交表单未授权，10-授权已分发 */
  submitStatus: SubmitStatus | null,

  /** 申请额度，1-0万3万、2-3万5万、3-5万10万、4-10万20万、5-20万以上 */
  applyLimit?:ApplyLimit

  /** 工作城市 */
  workCity: string

  /** 重新匹配结果 */
  rematchResult:RematchResult | null
}

/** 二要素校验 */
export interface IdentityNameVerify {

  /** 身份证号码 */
  identity: string | null

  /** 真实姓名 */
  realName: string | null

  /** 渠道码 */
  adChannelCode:string | null
}

/** 获取协议内容*/
export interface AgreementInfoResponse {

  /** 协议内容*/
  content: string,

  /** 是否填充0-不填充，1-填充*/
  signFieldFlag:SignFieldFlag
}

/** 发起产品申请-请求 */
export interface ApplyParams {

  /** 产品id*/
  productId: number,

  /** 入口类型，1-首页中插，2-首页弹窗、3-开屏、4-顶部推荐、5-首页列表、6-产品列表、7-随机推荐*/
  entranceType: EntranceType,

   /** 请求来源是APP还是H5 1-有钱来APP、2-普通H5来源、4-有钱助手来源、5-H5挽留来源*/
  appH5:APPH5,
}

/** 有钱来API下游VO */
export interface YqlDownstreamApiApplyVO {

  /** 撞库状态码*/
  bumpStatus: BumpStatus,

  /** 全流程产品名*/
  partnerProductName: string

  /** 全流程公司主体名*/
  partnerCompanyName:string

  /** 全流程下游产品logo URL*/
  partnerLogo: string

  /** 下游的协议列表L*/
  partnerProtocolList: PartnerProtocolList[]

  /** 产品价格*/
  productPrice:number
}

/** 发起产品申请-响应 */
export interface ApplyResponse {

  /** 申请状态*/
  applyStatus: ApplyStatus,

  /** 第三方跳转目标地址*/
  thirdpartyTargetUrl: string,

  /** h5联登产品协议列表*/
  h5Protocols: Protocols

  /** 是否联登（H5产品），0->否，1->是*/
  unionLoginFlag: UnionLogin

  /** 消息提示*/
  msg: string

  /** 是否是强制分发*/
  isForceDistribute: ForceDistributeStatus

  /** 是否属于一段结算的Api转h 5*/
  isOneStage: OneStageStatus

  /** 是否表单映射*/
  formMapping:boolean

  /** 有钱来API下游VO*/
  yqlDownstreamApiApplyVO:YqlDownstreamApiApplyVO
}
const indexApi = {
  // 保存客户申请信息
  saveApply(body: SaveParams) {
    return api.post('/api/user/info/save', body, {
      // mock: true
    })
  },

  // 暂存客户信息
  tempSave(body: TempSaveParams) {
    return api.post('/api/user/info/tempSave', body, {
      // mock: true
    })
  },

  // 二要素校验(身份证、姓名)
  identityNameVerify(body: IdentityNameVerify) {
    return api.post('/api/user/info/identityNameVerify', body, {
      // mock: true
    })
  },

  // 保存客户信息
  saveCustomerInfo(body: TempSaveParams) {
    return api.post<ProductMatchInfo>('/api/user/info/save', body, {
      // mock: true
    })
  },

  //大联登用户直接匹配
  directMatchInfo(body:DirectMatchInfo) {
    return api.post<GetProductInfoWidthStatus>('/api/user/info/direct/match', body, {
      //mock: true
    })
  },

  // 发起人脸身份识别
  faceVerify(body = {}) {
    return api.post<FaceVerifyResponse>('/api/user/info/faceVerify', body, {
      // mock: true
    })
  },

  //获取用户资料
  getApplyStatus(body = {}) {
    return api.get<ApiResponse<UserInfo>>('/api/user/info/detail', body, {
      // mock: true,
      showError: false,
      withoutCheck: true
    })
  },

  // 获取当前登录用户信息
  getLoginUserInfo(body = {}) {
    return api.get<UserInfo>('/api/getLoginUserInfo', body, {
      // mock: true,
      withoutCheck: true
    })
  },

  // 上报bug
  reportBugsnag(message: string) {
    return api.post('https://open.feishu.cn/open-apis/bot/v2/hook/32042512-2f7e-4137-9468-8146d90fb461', {
      // eslint-disable-next-line camelcase
      msg_type: 'text',
      content: { text: `yuanxin-h5 有bug上报到bugsnag了,\n${message}` }
    }, {
      withoutCheck: true
    })
  },

  // 获取用户表单流程中途推出后的信息获取
  getFomrSubmitStatus(body = {}) {
    return api.get<GetProductInfoWidthStatus>('/api/user/info/status/result', body, {
      // mock: true,
    })
  },

  // 认证接口
  confirmAuthorize(body = {}) {
    return api.put<AuthResponese>('/api/user/info/auth', body, {
      // mock: true,
    })
  },

  // 获取需要隐藏的表单项
  getFormItemShow() {
    return api.get('/api/user/info/form/show', null, {
      // mock: true,
    })
  },

  // 获取协议详情
  getAgreementInfo(id:string, productId?: number | null) {
    return api.get<AgreementInfoResponse>(`/api/user/info/protocol/${id}?productId=${productId ? productId : ''}`, null, {
      // mock: true,
    })
  },

  // 获取用户签署协议的记录
  getAgreementLogInfo(ids:any) {
    return api.get(`/api/user/info/log?${ids}`, null, {
      // mock: true,
    })
  },

  // 上游渠道联登处理 - 检查手机号是否登录
  checkMobileLogin(body:any) {
    return api.post('/api/upStreamUnionLogin/checkMobileLogin', body, {
      // mock: true,
    })
  },

  // 统计表单用户行为
  reportUserBehavior(type:any) {
    return api.post<UserInfo>(`/api/report/type?type=${type}`, null, {
      // mock: true,
      isCancelRequest: false
    })
  },

  // 统计匹配结果页产品列表项的用户点击
  reportUserProductClick(type:any) {
    return api.post<UserInfo>(`/api/report/click?type=${type}`, null, {
      // mock: true,
    })
  },

  // 上报H5手机设备(安卓-IOS)
  reportPhoneType(devicePlatForm:any) {
    return api.put(`/api/report/h5device?devicePlatForm=${devicePlatForm}`, null, {
      // mock: true,
    })
  },

  // 首页列表
  homeProductList(body:any) {
    return api.get('/api/product/columnRecommendProductList', body, {
      // mock: true,
    })
  },
  // 发起产品申请
  applyFor(body:ApplyParams) {
    return api.post<ApplyResponse>('/api/product/apply', body, {
      // mock: true,
    })
  },
  // 顶部推荐banner
  topRecommend() {
    return api.get('/api/product/topRecommend', null, {
      // mock: true,
    })
  },
  // 上报产品曝光（不鉴权）
  reportProductExposure(body = {}) {
    return api.get('/api/reportProductExposure', body, {
      // mock: true,
    })
  },

  // CRM账号认证 - 废弃 - 2024-12-10
  // authCrmAccount(body = {}) {
  //   return api.put<UserInfo>('/api/admin/third/wechat/bindOpenid', body, {
  //     // mock: true,
  //   })
  // },

  // crm账号认证新版
  authCrmAccount(body = {}) {
    return api.put<UserInfo>('/api/crm/admin/org/employee/wechat/bindOpenid', body, {
      // mock: true,
    })
  },

  // 第三方H5产品联登授权
  authH5Product(body = {}) {
    return api.get<any>('/api/product/h5product/union', body, {
      // mock: true,
    })
  },

  // 账号注销验证码
  logOutVerifyCode(body = {}) {
    return api.post('/api/cancellation/sendPhoneVerifyCode', body, {
      // mock: true,
    })
  },

  // 账号注销
  cancellation(body = {}) {
    return api.post<any>('/api/cancellation', body, {
      // mock: true,
    })
  },

  // 退出登录
  logout(body = {}) {
    return api.post<any>('/api/logout', body, {
      // mock: true,
    })
  },

  // 事件上报接口
  reportEventData(body:any) {
    return api.post<UserInfo>('/api/report/event', body, {
      // mock: true,
      isCancelRequest: false
    })
  },
  // 根据code获取页面配置
  getPageConfigByCode(body:any) {
    return api.get<any>('/api/pageConfig/getPageConfigByCode', body, {
      // mock: true,
    })
  },
  // 统计api 转 H5类型产品跳转
  apiH5JumpUrlSuccessFlag(body:any) {
    return api.post<any>('/api/product/apiH5JumpUrlSuccessFlag', body, {
      // mock: true,
      isCancelRequest: false
    })
  },
  // 获取app首页icon列表
  getAppIndexIconList() {
    return api.get<any>('/api/pageConfig/getAppIndexIconList', null, {
      // mock: true,
    })
  },
  // 首页弹窗
  getHomeDialogBanner(body:any) {
    return api.get<any>('/api/banner/homeDialogBanner', body, {
      // mock: true,
    })
  },
  // 前端日志上报
  reportLog(reportParam:any) {
    return api.post<UserInfo>(`/api/report/log?content=${reportParam}`, null, {
      // mock: true,
      isCancelRequest: false
    })
  },
  // 获取上游协议详情
  getProtocolContent(protocolId:string, productId?: string, maskName?: string, maskMobile?: string, workCity?: string) {
    return api.get(`/api/user/info/protocol2/getContent?protocolId=${protocolId}&productId=${productId}&maskName=${maskName}&maskMobile=${maskMobile}&workCity=${workCity}`, null, {
      // mock: true,
    })
  },

  // 获取登录日志
  getInformationProtocolById(uid:string) {
    return api.get<LoginInfo>(`/api/getUserLoginVO?uid=${uid}`, null, {
      // mock: true,
    })
  },

  // 使用路由码获取渠道码
  getAdChannelCodeByRouteCode(routeCode: string) {
    return api.get<string>(`/api/adChannel/exchange/adChannelCode?adRouteCode=${routeCode}`, null, {
      // mock: true
    })
  }
}
export default indexApi