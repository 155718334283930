/* eslint-disable camelcase */
/* eslint-disable no-magic-numbers */
// 皮肤8-还呗系列
import React, { useCallback, useState, useEffect, useMemo } from 'react'
import styles from './skin8.module.scss'
import classNames from 'classnames'
import { Toast } from 'antd-mobile'
import { parseNum, isPhone, Storage } from '@bihu/common-js'
import BigNumber from 'bignumber.js'
import { TOKEN, ENTER_AGREEMENT } from '@/constants/storage'
import NumberInput from '@/components/number-input/number-input'
import activityApi from '@/apis/activity'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import Icon from '@/components/icon/icon'
import sensors from 'sa-sdk-javascript'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SensorsType } from '@/constants/sensorsBurying'
import { CompanyType } from '@/constants/common'
import useDevAPI from '@/hooks/devApi'
interface Props {
  channelDetail: any // 渠道详情
  register: (code: string, phone: string, skinNum:number) => void
  loginAfterLocation:() => void
}

const Skin8: React.FC<Props> = props => {
  const { generateRandomPhoneNumber, getEnvironment } = useDevAPI()
  const [search] = useSearchParams()
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)
  const { channelDetail, register, loginAfterLocation } = props
  const navigate = useNavigate()
  useEffect(() => {
    const hasToken = Storage.get(TOKEN)
    // 是否需要获取定位
    if (adChannelCode && hasToken && channelDetail.location) {
      loginAfterLocation()
    }

    // 测试环境随机生成手机号
    if (getEnvironment() !== 'prod') {
      const radomPhoneNum = generateRandomPhoneNumber()
      localStorage.setItem('user_phone', radomPhoneNum)
      setPhone(radomPhoneNum)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 手机号码
  const [phone, setPhone] = useState('')
  // 验证码
  const [code, setCode] = useState('')

  // 是否显示验证码弹窗
  const [showPopup, setShowPopup] = useState('')

  // 选择协议
  const [checked, setChecked] = useState(false)
  // 是否抖动
  const [isShake, setIsShake] = useState(false)
  // 切换选中协议
  const checkedTaggle = () => {
    setChecked(!checked)
  }

  // 最小金额1万
  const [min] = useState(1000)
  // 最大金额5万
  let [max] = useState(50000)

  // 借款金额
  const [amount, setAmount] = useState<any>(10000)

  const amountRecommendArr:any = [
    {
      label: '￥10,000',
      value: 10000
    },
    {
      label: '￥25,000',
      value: 25000
    },
    {
      label: '全部借出',
      value: 200000
    },
  ]

  // 当前选中期数
  const [currentPeriods, setCurrentPeriods] = useState<number>(6)

  // 借款金额改变
  const amountOnChange = (str: string) => {

    str = str.replace(/[^\d]/g, '')
    let newValue:number | string = str
    if (+str > max) {
      // newValue = max
    }
    newValue = parseNum(newValue as unknown as number)
    if (newValue && newValue !== '0') {
      setAmount(newValue)
    } else {
      setAmount('')
    }
  }

  // 失去焦点
  const amountOnBlur = () => {
    let str = amount.replace(/[^\d]/g, '')
    max = 200000
    if (+str > max) {
      setAmount(parseNum(max))
      return Toast.show('您可借最大金额为200000元')
    }
    if (+str < min) {
      setAmount(parseNum(min))
      return Toast.show('最低可借金额为1000元起')
    }
  }

  // 月利率
  const [monthlyInterestRate] = useState(0.0056)

  // 每月需还金额 = （借款金额 + 总利息）/ 期数
  const monthRepayAmountFn = useCallback((currentMonth:number) => {
    const amountNew = amount.replace(',', '')

    if (!amountNew) {
      return '0'
    }

    let val = new BigNumber(amountNew).plus(totalInterest(currentMonth))
      .div(currentMonth)
      .toFixed(2)

    return val
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount])

  // 总利息 = 借款金额*月利率*期数
  const totalInterest = useCallback((currentMonth:number) => {
    const amountNew = amount.replace(',', '')

    if (!amountNew) {
      return '-'
    }

    let val = new BigNumber(amountNew).multipliedBy(monthlyInterestRate)
      .multipliedBy(currentMonth)
      .toFixed(2)

    return val
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount])

  // 跳转页面
  const openView = (path: string) => {
    Storage.set(ENTER_AGREEMENT, true)
    navigate(`/${path}-agreement?isShowHeader=true`)
  }

  // 定时器对象
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>()
  // 当前倒计时剩余时间
  const [time, setTime] = useState(0)

  // 倒计时时间
  const countDownTime = 60
  // 倒计时函数
  const countDownFn = () => {
    setTime(countDownTime)
  }

  useEffect(() => {
    if (time > 0) {
      let t = setTimeout(() => {
        setTime(time - 1)
      }, 1000)
      setTimer(t)
    } else {
      timer && clearTimeout(timer)
      setTimer(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time])

  // 获取验证码
  const getCode = async() => {
    if (!phone) {
      return Toast.show('请输入手机号码')
    }
    if (!isPhone(phone)) {
      return Toast.show('手机号码格式有误')
    }
    countDownFn()

    try {
      await activityApi.smsSend({
        mobile: +phone,
        channelCode: adChannelCode
      })
      localStorage.setItem('user_phone', phone)
      // 前端神策埋点 - 用户获取验证码
      sensors.track(SensorsType.cilck_get_verification_code, {
        ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code'),
        mobile: +phone
      })
    } catch (error) {
      timer && clearTimeout(timer)
    }

    // Toast.show('获取验证码成功')
  }

  const openPopup = () => {
    if (!phone) {
      return Toast.show('请输入手机号码')
    }
    if (!isPhone(phone)) {
      return Toast.show('手机号码格式有误')
    }
    if (!checked) {
      setIsShake(true)
      setTimeout(() => {
        setIsShake(false)
      }, 100)
      return Toast.show('请勾选注册、隐私及个人信息共享授权协议')
    }
    if (!time) {
      getCode()
    }
    setShowPopup('true')
  }

  // 立即注册
  // const register = async() => {
  //   if (!code) {
  //     return Toast.show('请输入验证码')
  //   }
  //   try {
  //     Toast.show({
  //       icon: 'loading',
  //       content: '领取中…',
  //       duration: 0,
  //     })
  //     // 前端神策埋点 - 用户点击登录
  //     sensors.track(SensorsType.click_login, {
  //       ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code'),
  //       mobile: +phone
  //     })
  //     const abTestType = 5
  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //     const res = await activityApi.login({
  //       mobile: phone,
  //       code,
  //       adChannelCode,
  //       abTestType,
  //       isFormReq: (type === 'getQuota' && adChannelCode !== 'YxNj9v'),
  //     })
  //     Storage.set('user_uid', res.loginUser.uid)
  //     sensors.login(res.loginUser.uid)
  //     Storage.set(TOKEN, res.token)
  //     if (channelDetail.location) {
  //       loginAfterLocation()
  //     } else {
  //       callBack()
  //     }
  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   } catch (error:any) {
  //     Toast.clear()
  //     // eslint-disable-next-line no-console
  //     console.log(error)
  //   }
  // }

  return (
    <div className={styles.skin5}>
      <header className={styles.header} style={{
        backgroundImage: channelValue?.includes('huanbeitheme') ? `url(${require('@imgs/mint-green/header_top.png')})` : `url(${require('@imgs/register/new-theme/head-bg.png')})`
      }}>
        <div className={styles.logoBox}>
          <img className={styles.logo} src={require('@imgs/youqianqianbao-logo.png')} alt="logo" />
          <div className={styles.logoBoxRight}>
            <Icon className={styles.youqianLogoText} type="youqianqianbao-text"></Icon>
            <span >申请简单通过率高</span>
          </div>
        </div>
        <div className={styles.inputWrapper}>
          <div className={styles.inputWrapperContainer}>
            <div className={styles.inputWrapperContainetTips}>最高可借200000元, 实际以审核为准</div>
            <div className={styles.inputBox}>
              <span className={styles.inputBoxSymbol}>
                ￥
              </span>
              <NumberInput
                placeholder={'最高可借200000元'}
                value={amount}
                onChange={amountOnChange}
                onBlur={amountOnBlur}
                numberFormat={{
                  decimal: false,
                  negative: false,
                  leadingZero: false,
                  maxLength: 6
                }}
              />
              <div className={styles.text}>
                (金额可修改)
                {/* <span className={styles.clear} onClick={() => setAmount(parseNum(min))}>x</span> */}
              </div>
            </div>
            <div className={styles.moneyOptions}>
              {
                amountRecommendArr.map((item:any) => {
                  return (
                    <div key={item.value} className={classNames(styles.moneyItem, amount === parseNum(item.value) && styles.moneyItemActive)} onClick={() => setAmount(parseNum(item.value))}>{ item.label }</div>
                  )
                })
              }
            </div>
            <div className={styles.line}></div>
            <div className={styles.moneyRateTips}>
              <div className={styles.moneyRateText1}>利率</div>
              <div className={styles.moneyRateText2}>年化利率18%-36% (单利)，实际以审核为准</div>
            </div>
          </div>
        </div>
      </header>
      <div className={styles.checkBox}>
        <div className={styles.loanTerm}>
          <h3>借多久</h3>
          <div className={styles.loanTermList}>
            {
              [6, 9, 12].map(item => {
                return <div key={item} className={classNames(styles.loanTermItem, item === currentPeriods && styles.active, styles.is360Channel)} onClick={() => setCurrentPeriods(item)}>
                  <p>{item}个月</p>
                </div>
              })
            }
          </div>
        </div>
        <div className={styles.bottomBody}>
          <div className={styles.phoneBox}>
            {/* <div className={styles.label}>手机号</div> */}
            <NumberInput
              placeholder="请输入本人实名认证手机号"
              value={phone}
              numberFormat={{
                decimal: false,
                negative: false,
                leadingZero: false,
                maxLength: 11
              }}
              onChange={val => {
                setPhone(val)
              }}
            />
          </div>
          <div className={styles.getCms} onClick={openPopup}>点击注册</div>
          <div className={classNames(styles.agreement, isShake && styles.animation)}>
            <i className={classNames(styles.checked, checked && styles.active)} onClick={checkedTaggle}></i>
              阅读并同意
            <span
              className={styles.primary}
              onClick={() => {
                openView('register')
              }}
            >
              《用户注册服务协议》
            </span>
            、
            <span className={styles.primary} onClick={() => {
              openView('privacy')
            }}>《隐私政策》</span>
            和
            <span className={styles.primary} onClick={() => {
              openView('information')
            }}>《个人信息共享授权协议》</span>
          </div>
        </div>
      </div>
      <div className={styles.tipBox}>
        <div className={styles.tip}>本平台是信息服务平台，不提供放贷业务</div>
        <div className={styles.tip}>平台向您展示的贷款产品或贷款咨询服务是由贷款服务机构</div>
        <div className={styles.tip}>(泛指为您提供贷款咨询服务或贷款发放服务的机构)</div>
        <div className={styles.tip}>或贷款中介咨询机构提供</div>
        <div className={styles.tip}>平台展示的贷款产品年利率范围18%-36%</div>
        <div className={styles.tip}>具体利率以第三方实际放款结果为准</div>
        <div className={styles.tip}>您最终获取的贷款额度、利率、放款时间</div>
        <div className={styles.tip}>以第三方机构实际审批结果为准</div>
        <div className={styles.tip}>温馨提示:请根据个人能力合理贷款，理性消费，避免逾期</div>
        <div className={styles.tip}>请不要相信任何要求您支付费用的信息、邮件、电话等不实信息</div>
        <div className={styles.tip}>
          {`${CompanyType.ZAXY_COMPANY.company} | ${CompanyType.ZAXY_COMPANY.company}`}
        </div>
        <div className={styles.tip}>客服电话：4006686025</div>
      </div>
      <div className={classNames(styles.dialogOverlay, showPopup && styles.showPopup)}>
        <div className={styles.dialogMain}>
          <div className={styles.close} onClick={() => setShowPopup('')}>X</div>
          <div className={styles.inputBox}>
            <NumberInput
              placeholder="请输入验证码"
              value={code}
              numberFormat={{
                decimal: false,
                negative: false,
                maxLength: 6
              }}
              onChange={val => {
                setCode(val)
              }}
            />
            {
              time ? <span className={classNames(styles.code, styles.codeText)}>剩余 {time}s</span>
                : <span className={styles.code} onClick={getCode}>获取验证码</span>
            }
          </div>
          <div className={styles.getQuota} onClick={() => register(code, phone, 8)}>获取额度</div>
        </div>
      </div>
    </div>
  )
}

export default Skin8
