/* eslint-disable no-magic-numbers */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

/** 用户资料状态，0-未填写、1-已填写未审核通过、2-已完成 */
export enum UserInfoStatus {

  /** 0-未填写 */
  UnFillForm = 0,

  /** 1-已填写未审核通过 */
  UnApproved = 1,

  /** 2-已完成 */
  Finish = 0
}

/** 是否允许表单覆盖，0-不允许覆盖，1-允许覆盖 */
export enum CoverStatus {

  /** 0-不允许覆盖 */
  DisCover = 0,

  /** 1-允许覆盖 */
  CanCover = 1,
}

/** 是否填充 0-不填充，1-填充 */
export enum SignFieldFlag {

  /** 0-不填充 */
  UnFilling = 0,

  /** 1-填充 */
  Filling = 1,
}

export enum EntranceType {

  /** 1-首页中插 */
  HomeInterstitial = 1,

   /** 2-首页弹窗 */
  HomePopup = 2,

  /** 3-开屏 */
  Splash = 3,

  /** 4-顶部推荐 */
  TopRecommendation = 4,

  /** 5-首页列表 */
  HomeList = 5,

  /** 6-产品列表 */
  ProductList = 6,

  /**  7-随机推荐 */
  RandomRecommendation = 7
}

/** 请求来源是APP还是H5 */
export enum APPH5 {

  /** 1 - 有钱来APP */
  YouQianLaiApp = 1,

  /** 2 - 普通H5来源 */
  GeneralH5Source = 2,

  /**
   * 3 - 马甲包
   * 已废弃 - 有钱掌柜 2024-01-01 有钱掌柜上架APP商城失败
   */
  // Deprecated_YouQianZhangGui = 3,

  /** 4 - 有钱助手来源 */
  YouQianAssistantSource = 4,

  /** 5 - H5挽留来源 */
  H5RetentionSource = 5,
}

export enum YesNo {

  /** 1 - 是 */
  Yes = 1,

  /** 0 - 否 */
  No = 0,
}

/** 是否联登（H5产品），0->否，1->是 */
export enum UnionLogin {

  /** 0-否 */
  UnApproved = YesNo.No,

  /** 1-是 */
  Approved = YesNo.Yes,
}

/** 是否是强制分发0：否,1：是*/
export enum ForceDistributeStatus {

  /** 0 - 非强制分发 */
  NotForce = YesNo.No,

  /** 1 - 是强制分发 */
  Force = YesNo.Yes,
}

/** 是否属于一段结算的Api转h5 0：否 1：是  */
export enum OneStageStatus {

  /** 0 - 不属于一段结算的Api转H5 */
  NotOneStage = YesNo.No,

  /** 1 - 属于一段结算的Api转H5 */
  OneStage = YesNo.Yes,

}

/** 用户直接匹配类型 0：老用户二次进入 1：大联登  */
export enum DirectMatchStatus {

  /** 0 - 老用户二次进入 */
  OLD_USER = 0,

  /** 1 - 大联登 */
  BIG_UNION = 1,

}