/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable no-magic-numbers */
// 皮肤-360渠道
import React, { useState, useEffect, useMemo } from 'react'
import styles from './skin17.module.scss'
import classNames from 'classnames'
import { Toast, Steps } from 'antd-mobile'
import { isPhone, Storage, formatTime, debounce } from '@bihu/common-js'
import { TOKEN, ENTER_AGREEMENT } from '@/constants/storage'
import NumberInput from '@/components/number-input/number-input'
import activityApi, { ChannelDetailResponse } from '@/apis/activity'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import Icon from '@/components/icon/icon'
import sensors from 'sa-sdk-javascript'
import { SensorsType } from '@/constants/sensorsBurying'
import useDevAPI from '@/hooks/devApi'
import { CloseCircleFill, DownOutline } from 'antd-mobile-icons'
import { Step } from 'antd-mobile/es/components/steps/step'
import { CompanyType } from '@/constants/common'

interface Props {
  channelDetail: ChannelDetailResponse | undefined // 渠道详情
  register: (code: string, phone: string, skinNum:number) => void
  loginAfterLocation:() => void
}

interface LoanDetailsItem {
  date: string //日期
  principal: number, //本金
  interest:number // 利息
}

const Skin17: React.FC<Props> = props => {
  const { generateRandomPhoneNumber, getEnvironment } = useDevAPI()
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const { adChannelCode, channelValue } = useSelector((state: RootState) => state.channel)
  const { channelDetail, register, loginAfterLocation } = props
  const [showLoanDetails, setShowLoanDetails] = useState(false)
  const [loanDetails, setLoanDetails] = useState<LoanDetailsItem[]>([])
  let min = 2000
  let max = 200000

  useEffect(() => {
    const hasToken = Storage.get(TOKEN)
    // 是否需要获取定位
    if (adChannelCode && hasToken && channelDetail?.location) {
      loginAfterLocation()
    }

    // 测试环境随机生成手机号
    if (getEnvironment() !== 'prod') {
      const radomPhoneNum = generateRandomPhoneNumber()
      localStorage.setItem('user_phone', radomPhoneNum)
      setPhone(radomPhoneNum)
    }
  }, [])

  // 手机号码
  const [phone, setPhone] = useState('')
  // 验证码
  const [code, setCode] = useState('')

  // 是否显示验证码弹窗
  const [showPopup, setShowPopup] = useState(false)

  // 选择协议
  const [checked, setChecked] = useState(false)
  // 是否抖动
  const [isShake, setIsShake] = useState(false)
  // 切换选中协议
  const checkedTaggle = () => {
    setChecked(!checked)
  }

  // 借款金额
  const [amount, setAmount] = useState<string>('20000')

  // 当前选中期数
  const [currentPeriods, setCurrentPeriods] = useState<number>(12)

  // 借款金额改变
  const amountOnChange = (str: string) => {

    str = str.replace(/[^\d]/g, '')
    let newValue:number | string = str
    if (+str > max) {
      // newValue = max
    }
    if (newValue && newValue !== '0') {
      setAmount(newValue)
    } else {
      setAmount('')
    }
  }

  // 失去焦点
  const amountOnBlur = () => {
    let str = amount.replace(/[^\d]/g, '')

    if (+str > max) {
      setAmount(max.toString())
      return Toast.show('您可借最大金额为200000元')
    }

    if (+str < min) {
      setAmount(min.toString())
      return Toast.show('最低可借金额为2000元起')
    }
  }


  // 跳转页面
  const openView = (path: string) => {
    Storage.set(ENTER_AGREEMENT, true)
    navigate(`/${path}-agreement?isShowHeader=true`)
  }

  // 定时器对象
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>()
  // 当前倒计时剩余时间
  const [time, setTime] = useState(0)

  // 倒计时时间
  const countDownTime = 60
  // 倒计时函数
  const countDownFn = () => {
    setTime(countDownTime)
  }

  useEffect(() => {
    if (time > 0) {
      let t = setTimeout(() => {
        setTime(time - 1)
      }, 1000)
      setTimer(t)
    } else {
      timer && clearTimeout(timer)
      setTimer(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time])


  // 获取验证码
  const getCode = async() => {
    if (!phone) {
      return Toast.show('请输入手机号码')
    }
    if (!isPhone(phone)) {
      return Toast.show('手机号码格式有误')
    }
    countDownFn()

    try {
      await activityApi.smsSend({
        mobile: +phone,
        channelCode: adChannelCode
      })
      localStorage.setItem('user_phone', phone)

      // 前端神策埋点 - 用户获取验证码
      sensors.track(SensorsType.cilck_get_verification_code, {
        ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code'),
        mobile: +phone
      })
    } catch (error) {
      timer && clearTimeout(timer)
    }
    // Toast.show('获取验证码成功')
  }

  const openPopup = () => {
    if (!phone) {
      return Toast.show('请输入手机号码')
    }
    if (!isPhone(phone)) {
      return Toast.show('手机号码格式有误')
    }
    if (!checked) {
      setIsShake(true)
      setTimeout(() => {
        setIsShake(false)
      }, 100)
      return Toast.show('请勾选注册和隐私协议')
    }
    if (!time) {
      getCode()
    }
    setShowPopup(true)
  }

  const calculateInterest = (times: number, amountNum: number) => {
    let startDate = new Date()

    let data: LoanDetailsItem[] = []
    for (let i = 0; i < times; i++) {
      // 设置当前日期
      let currentDate = new Date(startDate)
      currentDate.setMonth(currentDate.getMonth() + i + 1)
      let formattedDate = formatTime(currentDate, 'MM-DD')

      let principal = +(amountNum / times).toFixed(2) // 本金
      let interest = +(amountNum * (times - i) / times * 0.006).toFixed(2)

      // 如果月份超过11（即12月），则重置为1月
      if (currentDate.getMonth() > 11) {
        formattedDate = formatTime(new Date(currentDate.getFullYear(), 0), 'MM-DD')
      }


      data.push({
        principal,
        interest,
        date: formattedDate
      })
    }
    setLoanDetails(data)
  }

  // 计算卡片主题色
  const cardBackgroundColor = useMemo(() => {
    switch (true) {
      case channelValue.includes('shallowBlue'):
        return '#70B3F7'
      case channelValue.includes('huanbeitheme'):
        return '#92CDD7'
      default:
        return '#F7707B'
    }
  }, [channelValue])

  const debounceRegister = useMemo(() => debounce(register, 500, false), [])

  useEffect(() => {
    calculateInterest(currentPeriods, +amount)
  }, [currentPeriods, amount])

  return (
    <div className={styles.skin17}>
      <div className={styles.topIcon}>
        <Icon type="CheckCircle" className={styles.icon}></Icon>
        <span>恭喜，您的备用金账户可取用</span>
      </div>
      <div className={styles.topCard} style={{ backgroundColor: cardBackgroundColor }}>
        <h4 className={styles.cardTitle}>最高可借20万</h4>
        <img className={styles.topCardBackgroundImg} src={require('@/assets/imgs/register/skin17backgound.png')} alt="youqianqianbao-cardImg" />
        <div className={styles.cardInputBox}>
          <span className={styles.inputBoxSymbol}>
                ￥
          </span>
          <NumberInput
            placeholder={'最高可借200000元'}
            value={amount}
            onChange={amountOnChange}
            onBlur={amountOnBlur}
            numberFormat={{
              decimal: false,
              negative: false,
              leadingZero: false,
              maxLength: 6
            }}
          />
          <div className={styles.text}>
            (金额可修改)
            <CloseCircleFill onClick={() => setAmount('20000')} color="#ffffff" className={styles.closeIcon} />
          </div>
        </div>
        <div className={styles.cardPromptText}>次日可还款，免手续，免剩余天数利息</div>
        <div className={styles.cardBottomBox}>
          <div className={styles.borrowAmount}>
            <h4>可借金额 (元)</h4>
            <div>
              <span>50000</span>
              <Icon type="RightCircle" className={styles.rightCircleIcon} onClick={() => {
                Toast.show({
                  content: '请点击“去领取”进入查看',
                  duration: 2000
                })
              }}></Icon>
            </div>
          </div>
          <div className={styles.line}></div>
          <div className={styles.borrowAmount} style={{ paddingLeft: '38px' }}>
            <h4>可借余额 (元)</h4>
            <div>
              <span>0</span>
              <Icon type="RightCircle" className={styles.rightCircleIcon} onClick={() => {
                Toast.show({
                  content: '请点击“去领取”进入查看',
                  duration: 2000
                })
              }}></Icon>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.chooseTimeCard}>
        <div className={styles.chooseTimeTitle}>
          <span className={styles.chooseTimeLeft}>借多久</span>
          <span className={styles.chooseTimeRight}>年利率低至 <span>7.2%</span>，10000元1天利息2元起</span>
        </div>
        <div className={styles.loanTermList}>
          {
            [6, 9, 12, 36].map(item => {
              return <div key={item} className={classNames(styles.loanTermItem, item === currentPeriods && styles.active, styles.is360Channel)} onClick={() => setCurrentPeriods(item)}>
                <p>{item}个月</p>
              </div>
            })
          }
        </div>
      </div>
      <div className={styles.LoanDetailsCard}>
        <div className={styles.showDetailsBox}>
          <div>
            <span className={styles.showDetailsMainText}>还款明细</span>
            <span className={styles.showDetailsText}>总利息￥{loanDetails.reduce((pre, prv) => pre + prv.interest, 0).toFixed(2) }</span>
          </div>
          <div>
            {
              showLoanDetails
                ? <span className={styles.shrinkBtn} onClick={() => setShowLoanDetails(!showLoanDetails)}>收起</span>
                : <span className={styles.shrinkBtn} onClick={() => setShowLoanDetails(!showLoanDetails)}>展开</span>
            }
            <DownOutline style={{
              marginLeft: '2px',
              transition: 'transform 0.3s ease',
              transform: showLoanDetails ? 'rotate(180deg)' : ''
            }} />
          </div>
        </div>
        <div className={styles.stepsBox}>
          <div className={styles.listTimeBox}>
            {
              loanDetails.map((item, index) => {
                function formatDate(dateStr:string) {
                  return dateStr.replace(/-/g, '月').replace(/(\d{2})$/, '$1日')
                }
                const timeDetais = (date: string, i: number) => {
                  return <div key={date + index} className={styles.numberCycle}>
                    <div className={styles.numberCycleTitle}>{index === 0 ? '首期' : `${i + 1}期`}</div>
                    <div>{ formatDate(date).replace(/^0/g, '')}</div>
                  </div>
                }
                if (showLoanDetails) {
                  return timeDetais(item.date, index)
                } else if (!showLoanDetails && index < 2) {
                  return timeDetais(item.date, index)
                } else {
                  return null
                }
              })
            }
          </div>
          <Steps direction="vertical" current={undefined}>
            {
              loanDetails.map((item, index) => {
                if (showLoanDetails) {
                  return <Step
                    key={item.date + index}
                    title={<span style={{
                      fontWeight: '600',
                      color: '#262626'
                    }}>{ `应还¥${(Number(item.principal) + Number(item.interest)).toFixed(2)}`}</span>}
                    description={`本金¥${item.principal}+利息约¥${item.interest}`}
                    icon={<div className={styles.listTimePoint}></div>}
                  />
                } else if (!showLoanDetails && index < 2) {
                  return <Step
                    key={item.date + index}
                    title={<span style={{
                      fontWeight: '600',
                      color: '#262626'
                    }}>{ `应还¥${(Number(item.principal) + Number(item.interest)).toFixed(2)}`}</span>}
                    description={`本金¥${item.principal}+利息约¥${item.interest}`}
                    icon={<div className={styles.listTimePoint}></div>}
                  />
                } else {
                  return null
                }
              })
            }
          </Steps>
        </div>
      </div>
      <div className={styles.bottomBody}>
        <div className={styles.phoneBox}>
          {/* <div className={styles.label}>手机号</div> */}
          <NumberInput
            placeholder="输入手机号领取金额"
            value={phone}
            numberFormat={{
              decimal: false,
              negative: false,
              leadingZero: false,
              maxLength: 11
            }}
            onChange={val => {
              setPhone(val)
            }}
          />
        </div>
        <div className={styles.getCms} onClick={openPopup}>去领取</div>
        {/* <button onClick={demo}>测试</button> */}
        <div className={classNames(styles.agreement, isShake && styles.animation)}>
          <i className={classNames(styles.checked, checked && styles.active)} onClick={checkedTaggle}></i>
              阅读并同意
          <span
            className={styles.primary}
            onClick={() => {
              openView('register')
            }}
          >
              《用户注册服务协议》
          </span>
            和
          <span className={styles.primary} onClick={() => {
            openView('privacy')
          }}>《隐私政策》</span>
          <span className={styles.primary} onClick={() => {
            openView('information')
          }}>《个人信息共享授权协议》</span>
        </div>
      </div>
      <div className={styles.tipBox}>
        <div className={styles.tip}>本平台是信息服务平台，不提供放贷业务</div>
        <div className={styles.tip}>平台向您展示的贷款产品或贷款咨询服务是由贷款服务机构</div>
        <div className={styles.tip}>(泛指为您提供贷款咨询服务或贷款发放服务的机构)</div>
        <div className={styles.tip}>或贷款中介咨询机构提供</div>
        <div className={styles.tip}>平台展示的贷款产品年利率范围7.2%-24%</div>
        <div className={styles.tip}>具体利率以第三方实际放款结果为准</div>
        <div className={styles.tip}>您最终获取的贷款额度、利率、放款时间</div>
        <div className={styles.tip}>以第三方机构实际审批结果为准</div>
        <div className={styles.tip}>温馨提示:请根据个人能力合理贷款，理性消费，避免逾期</div>
        <div className={styles.tip}>请不要相信任何要求您支付费用的信息、邮件、电话等不实信息</div>
        {channelValue?.includes('xinyonghuaRegisterPage') && <div className={styles.tip}>贷款资金由正规金融持牌放款机构提供</div>}
        {channelValue?.includes('registerTips') && <div className={styles.tip}>资金来源：{ CompanyType.ZAXY_COMPANY.company}</div>}
        {channelValue?.includes('registerTips') && <div className={styles.tip}>贷款有风险 借款需谨慎</div>}
        {
          channelValue.includes('zhonganXy') && <div className={styles.tip}>{ `${CompanyType.ZAXY_COMPANY.recordNumber} | ${CompanyType.ZAXY_COMPANY.company}`}</div>
        }
        <div className={styles.tip}>客服电话：4006686025</div>
      </div>
      <div className={classNames(styles.dialogOverlay, showPopup && styles.showPopup)}>
        <div className={styles.dialogMain}>
          <div className={styles.close} onClick={() => setShowPopup(false)}>X</div>
          <div className={styles.inputBox}>
            <NumberInput
              placeholder="请输入验证码"
              value={code}
              numberFormat={{
                decimal: false,
                negative: false,
                maxLength: 6
              }}
              onChange={val => {
                setCode(val)
              }}
            />
            {
              time ? <span className={classNames(styles.code, styles.codeText)}>剩余 {time}s</span>
                : <span className={styles.code} onClick={getCode}>获取验证码</span>
            }
          </div>
          <div className={styles.getQuota} onClick={() => debounceRegister(code, phone, 15)}>获取额度</div>
        </div>
      </div>
    </div>
  )
}

export default Skin17
