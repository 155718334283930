/* eslint-disable camelcase */
/* eslint-disable no-magic-numbers */
// 皮肤-360渠道
import React, { useCallback, useState, useEffect, useRef } from 'react'
import styles from './skin15.module.scss'
import classNames from 'classnames'
import { Toast } from 'antd-mobile'
import { parseNum, isPhone, Storage } from '@bihu/common-js'
import BigNumber from 'bignumber.js'
import getHostNameObj from '@/hooks/get-host-name-obj'
import { TOKEN, ENTER_AGREEMENT } from '@/constants/storage'
import NumberInput from '@/components/number-input/number-input'
import activityApi from '@/apis/activity'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Icon from '@/components/icon/icon'
import { phoneList, namesList } from './assets/assets'
import sensors from 'sa-sdk-javascript'
import { SensorsType } from '@/constants/sensorsBurying'
import useDevAPI from '@/hooks/devApi'

interface Props {
  channelDetail: any // 渠道详情
  register: (code: string, phone: string, skinNum:number) => void
  loginAfterLocation:() => void
}

interface cordItem {
  avatar: any,
  name: string,
  phone: string,
  amount: number,
  applyDate: string,
  approveDate:string
}

const Skin15: React.FC<Props> = props => {
  const { generateRandomPhoneNumber, getEnvironment } = useDevAPI()
  const navigate = useNavigate()
  const { channelDetail, register, loginAfterLocation } = props
  const hostNameObj = getHostNameObj()
  const scrolledBoxRef = useRef<HTMLDivElement>(null)

  const [search] = useSearchParams()

  // 渠道编码
  const [adChannelCode, setAdChannelCode] = useState('')

  useEffect(() => {
    const hasToken = Storage.get(TOKEN)
    const code = search.get('adChannelCode')
    randomUserCards()
    if (code) {
      setAdChannelCode(code)
      localStorage.setItem('ad_channel_code', code)
      // 是否需要获取定位
      if (hasToken && channelDetail.location) {
        loginAfterLocation()
      }
    }

    // 测试环境随机生成手机号
    if (getEnvironment() !== 'prod') {
      const radomPhoneNum = generateRandomPhoneNumber()
      localStorage.setItem('user_phone', radomPhoneNum)
      setPhone(radomPhoneNum)
    }
  }, [])

  // 手机号码
  const [phone, setPhone] = useState('')
  // 验证码
  const [code, setCode] = useState('')

  // 是否显示验证码弹窗
  const [showPopup, setShowPopup] = useState('')

  // 选择协议
  const [checked, setChecked] = useState(false)
  // 是否抖动
  const [isShake, setIsShake] = useState(false)
  // 切换选中协议
  const checkedTaggle = () => {
    setChecked(!checked)
  }

  // 最小金额1万
  const [min] = useState(1000)
  // 最大金额5万
  let [max] = useState(50000)

  // 借款金额
  const [amount, setAmount] = useState<any>(20000)

  const amountRecommendArr:any = [
    {
      label: '￥10,000',
      value: 10000
    },
    {
      label: '￥25,000',
      value: 25000
    },
    {
      label: '全部借出',
      value: 200000
    },
  ]

  // 当前选中期数
  const [currentPeriods, setCurrentPeriods] = useState<number>(6)

  // 借款金额改变
  const amountOnChange = (str: string) => {

    str = str.replace(/[^\d]/g, '')
    let newValue:number | string = str
    if (+str > max) {
      // newValue = max
    }
    newValue = parseNum(newValue as unknown as number)
    if (newValue && newValue !== '0') {
      setAmount(newValue)
    } else {
      setAmount('')
    }
  }

  // 失去焦点
  const amountOnBlur = () => {
    let str = amount.replace(/[^\d]/g, '')
    max = 200000
    if (+str > max) {
      setAmount(parseNum(max))
      return Toast.show('您可借最大金额为200000元')
    }
    if (+str < min) {
      setAmount(parseNum(min))
      return Toast.show('最低可借金额为1000元起')
    }
  }

  // 月利率
  const [monthlyInterestRate] = useState(0.0056)

  // 每月需还金额 = （借款金额 + 总利息）/ 期数
  const monthRepayAmountFn = useCallback((currentMonth:number) => {
    const amountNew = amount.replace(',', '')

    if (!amountNew) {
      return '0'
    }

    let val = new BigNumber(amountNew).plus(totalInterest(currentMonth))
      .div(currentMonth)
      .toFixed(2)

    return val
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount])

  // 总利息 = 借款金额*月利率*期数
  const totalInterest = useCallback((currentMonth:number) => {
    const amountNew = amount.replace(',', '')

    if (!amountNew) {
      return '-'
    }

    let val = new BigNumber(amountNew).multipliedBy(monthlyInterestRate)
      .multipliedBy(currentMonth)
      .toFixed(2)

    return val
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount])

  // 跳转页面
  const openView = (path: string) => {
    Storage.set(ENTER_AGREEMENT, true)
    navigate(`/${path}-agreement?isShowHeader=true`)
  }

  // 定时器对象
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>()
  // 当前倒计时剩余时间
  const [time, setTime] = useState(0)

  // 倒计时时间
  const countDownTime = 60
  // 倒计时函数
  const countDownFn = () => {
    setTime(countDownTime)
  }

  useEffect(() => {
    if (time > 0) {
      let t = setTimeout(() => {
        setTime(time - 1)
      }, 1000)
      setTimer(t)
    } else {
      timer && clearTimeout(timer)
      setTimer(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time])

  // 获取验证码
  const getCode = async() => {
    if (!phone) {
      return Toast.show('请输入手机号码')
    }
    if (!isPhone(phone)) {
      return Toast.show('手机号码格式有误')
    }
    countDownFn()

    try {
      await activityApi.smsSend({
        mobile: +phone,
        channelCode: adChannelCode
      })

      // 前端神策埋点 - 用户获取验证码
      sensors.track(SensorsType.cilck_get_verification_code, {
        ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code'),
        mobile: +phone
      })
    } catch (error) {
      timer && clearTimeout(timer)
    }

    // Toast.show('获取验证码成功')
  }

  const openPopup = () => {
    if (!phone) {
      return Toast.show('请输入手机号码')
    }
    if (!isPhone(phone)) {
      return Toast.show('手机号码格式有误')
    }
    if (!checked) {
      setIsShake(true)
      setTimeout(() => {
        setIsShake(false)
      }, 100)
      return Toast.show('请勾选注册、隐私及个人信息共享授权协议')
    }
    if (!time) {
      getCode()
    }
    setShowPopup('true')
  }

  const examineIcons = [
    {
      icon: 'xiangbei-phone',
      content: '手机号入网时长'
    },
    {
      icon: 'xiangbei-user',
      content: '年龄要求'
    },
    {
      icon: 'xiangbei-handshake',
      content: '履约行为初审'
    }
  ]

  const [cords, setCords] = useState<cordItem[]>([])

  const randomUserCards = () => {
    // 随机获取数组长度中的一个元素
    const getRandomElement = (arr:Array<number|string>) => arr[Math.floor(Math.random() * arr.length)]
    // 生成随机数范围
    const randomNum = (minNum:number, maxNum:number) => {
      return parseInt((Math.random() * (maxNum - minNum + 1) + minNum).toString(), 10)
    }
    const getRandomPhone = () => `${getRandomElement(phoneList)}****${randomNum(1000, 9999)}`
    const getRandomAmount = () => {
      const randomAmount = randomNum(3000, 300000)
      return randomAmount < 10000 ? Math.floor(randomAmount / 1000) * 1000 : Math.floor(randomAmount / 10000) * 10000
    }
    const getCurrentDate = () => new Date().toISOString()
      .split('T')[0]
    const records:cordItem[] = cords
    for (let i = 0; i < 5; i++) {
      const surname = getRandomElement(namesList)
      const stars = '*'.repeat(randomNum(1, 2))
      const record = {
        avatar: require(`@/pages/register/components/skin15/assets/picture/头像-${randomNum(1, 50)}.png`), // 假设头像命名为 avatar1.png 至 avatar50.png
        name: `${surname}${stars}`,
        phone: getRandomPhone(),
        amount: getRandomAmount(),
        applyDate: getCurrentDate(),
        approveDate: getCurrentDate(),
      }
      records.push(record)
    }


    setCords([...records])
  }

  // 监听滚动事件，检查是否滚动到最右侧
  useEffect(() => {
    const handleScroll = () => {
      const container = scrolledBoxRef.current
      if (container) {
        const scrollRight = container.scrollLeft + container.clientWidth + 20
        const isAtRightEnd = scrollRight >= container.scrollWidth

        if (isAtRightEnd) {
          randomUserCards() // 滚动到最右侧时调用 randomUserCards
        }
      }
    }

    const scrolledBox = scrolledBoxRef.current
    if (scrolledBox) {
      scrolledBox.addEventListener('scroll', handleScroll)
    }

    // 清理事件监听器
    return () => {
      if (scrolledBox) {
        scrolledBox.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    const scrolledBox = scrolledBoxRef.current
    if (scrolledBox) {
      const scrollToRight = () => {
        const { scrollWidth } = scrolledBox
        const { clientWidth } = scrolledBox
        const scrollPosition = scrolledBox.scrollLeft

        // 如果还没有滚动到最右侧，继续滚动
        if (scrollPosition < scrollWidth - clientWidth) {
          scrolledBox.scrollLeft += 1
        } else {
          clearInterval(scrollInterval)
        }
      }

      const scrollInterval = setInterval(scrollToRight, 10)

      // 清理定时器
      return () => {
        clearInterval(scrollInterval)
      }
    }
  }, [cords])


  return (
    <div className={styles.skin15}>
      <header className={styles.header}>
        <div className={styles.examineBox}>
          <div className={styles.examineTitle}>您的手机号已通过平台额度初审</div>
          <div className={styles.examineIconsBox}>
            {
              examineIcons.map(item => {
                return <div key={item.content} className={styles.examineItem}>
                  <div className={styles.examineIcon}>
                    {/* <img src={item.icon} alt="" /> */}
                    <Icon className={styles.icon} type={item.icon}></Icon>
                    <div className={styles.checkedBox}>
                      <img src={require('@/assets/imgs/xiangbei/check.png')} alt="" />
                    </div>
                  </div>
                  <span>{ item.content}</span>
                </div>
              })
            }
          </div>
        </div>
        <div className={styles.inputWrapper}>
          <div className={styles.inputWrapperTitle}>
            <span >你想借多少?</span>
            <span >（具体金额以审批结果为准）</span>
          </div>
          <div className={styles.inputBox}>
            <div className={styles.input}>
              <NumberInput
                placeholder={'最高可借200000元'}
                value={amount}
                onChange={amountOnChange}
                onBlur={amountOnBlur}
                numberFormat={{
                  decimal: false,
                  negative: false,
                  leadingZero: false,
                  maxLength: 6
                }}
              />
            </div>
            <div className={styles.inputBtnBox}>
              <span onClick={() => setAmount(parseNum(200000))}>全部借出</span>
              <span>(金额可修改)</span>
              <Icon className={styles.clearIcon} type="xiangbei-close" onClick={() => setAmount(parseNum(10000))}></Icon>
            </div>
          </div>
          <div className={styles.chooseBox}>
            <div className={styles.chooseLeft}>借款期限</div>
            <div className={styles.chooseRight}>
              <span>可选 3/6/12/36个月</span>
            </div>
          </div>
          <div className={styles.chooseBox}>
            <div className={styles.chooseLeft}>还款方式</div>
            <div className={styles.chooseRight}>
              <span>随借随还</span>
              <span>具体方式需根据最终资方而定</span>
            </div>
          </div>
        </div>
      </header>
      <div className={styles.checkBox}>
        <div className={styles.bottomBody}>
          <div className={styles.phoneBox}>
            <NumberInput
              placeholder="请输入手机号"
              value={phone}
              numberFormat={{
                decimal: false,
                negative: false,
                leadingZero: false,
                maxLength: 11
              }}
              onChange={val => {
                setPhone(val)
              }}
            />
          </div>
          <div className={styles.getCms} onClick={openPopup}>{['hKvhDm', 'XPPL9v'].indexOf(adChannelCode) !== -1 ? '点击注册' : '去借钱'}</div>
          <div className={classNames(styles.agreement, isShake && styles.animation)}>
            <i className={classNames(styles.checked, checked && styles.active)} onClick={checkedTaggle}></i>
              阅读并同意
            <span
              className={styles.primary}
              onClick={() => {
                openView('register')
              }}
            >
              《用户注册服务协议》
            </span>
            、
            <span className={styles.primary} onClick={() => {
              openView('privacy')
            }}>《隐私政策》</span>
            和
            <span className={styles.primary} onClick={() => {
              openView('information')
            }}>《个人信息共享授权协议》</span>
          </div>
        </div>
      </div>
      <div className={styles.scrolledList}>
        <div className={styles.scrollTips}>
          <div></div>
          <span>最新动态</span>
        </div>
        <div className={styles.scrolledBox} ref={scrolledBoxRef}>
          {cords.map((item:cordItem) => {
            return <div className={styles.userCard} key={item.phone + item.amount + item.name}>
              <div className={styles.userHeader}>
                <div className={styles.userHeaderLeft}>
                  <img src={item.avatar} alt="" />
                  <div className={styles.userInfo}>
                    <span>{ item.name}</span>
                    <span>{ item.phone}</span>
                  </div>
                </div>
                <div className={styles.userHeaderRight}>
                  <div className={styles.userAmount}>{ parseNum(item.amount)}</div>
                  <div>获取额度</div>
                </div>
              </div>
              <div className={styles.userTime}>
                <div>申请日期：{ item.applyDate}</div>
                <div>获批日期：{ item.approveDate}</div>
              </div>
            </div>
          })
          }
        </div>
      </div>
      <div className={styles.tipBox}>
        <div className={styles.tip}>本平台是信息服务平台，不提供放贷业务</div>
        <div className={styles.tip}>平台向您展示的贷款产品或贷款咨询服务是由贷款服务机构</div>
        <div className={styles.tip}>(泛指为您提供贷款咨询服务或贷款发放服务的机构)</div>
        <div className={styles.tip}>或贷款中介咨询机构提供</div>
        <div className={styles.tip}>平台展示的贷款产品年利率范围7.2%-24%</div>
        <div className={styles.tip}>具体利率以第三方实际放款结果为准</div>
        <div className={styles.tip}>您最终获取的贷款额度、利率、放款时间</div>
        <div className={styles.tip}>以第三方机构实际审批结果为准</div>
        <div className={styles.tip}>温馨提示:请根据个人能力合理贷款，理性消费，避免逾期</div>
        <div className={styles.tip}>请不要相信任何要求您支付费用的信息、邮件、电话等不实信息</div>
        <div className={styles.tip}>客服电话：4006686025</div>
      </div>
      <div className={classNames(styles.dialogOverlay, showPopup && styles.showPopup)}>
        <div className={styles.dialogMain}>
          <div className={styles.close} onClick={() => setShowPopup('')}>X</div>
          <div className={styles.inputBox}>
            <NumberInput
              placeholder="请输入验证码"
              value={code}
              numberFormat={{
                decimal: false,
                negative: false,
                maxLength: 6
              }}
              onChange={val => {
                setCode(val)
              }}
            />
            {
              time ? <span className={classNames(styles.code, styles.codeText)}>剩余 {time}s</span>
                : <span className={styles.code} onClick={getCode}>获取验证码</span>
            }
          </div>
          <div className={styles.getQuota} onClick={() => register(code, phone, 15)}>获取额度</div>
        </div>
      </div>
    </div>
  )
}

declare const window: Window & { WebViewJavascriptBridge: any, WVJBCallbacks: any }

export default Skin15
