// 协议列表
import React, { useMemo } from 'react'
import styles from './protocol-list.module.scss'
import { Divider } from 'antd-mobile'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { selectUserInfo } from '@/store/user'
import { useAppSelector } from '@/store/hooks'
import { encryptString } from '@/utils/utils'
import { formatTime } from '@bihu/common-js'
import PrivacyAgreement from '@/components/privacy-agreement/privacy-agreement'
import { UserInfo } from '@/types/apis/user'

interface Props {
  children?: React.ReactNode,
  title?: string,
  company?: string | null
  loginInfo?: UserInfo
  protocol?:ProtocolListType[]
}

// 协议列表类型
export enum ProtocolListType {
  'generalAuthorization' = 1,
  'noCollegeStudents' = 2,
  'youqianqianbaoPrivacy' = 3,
  'defaultProtocol' = 4
}


const ProtocolList: React.FC<Props> = props => {
  const { channelValue } = useSelector((state: RootState) => state.channel)
  const { children, title, company, loginInfo,protocol } = props
  // 用户信息
  const userInfo = useAppSelector(selectUserInfo)
  const phone = localStorage.getItem('user_phone') || userInfo?.mobile || ''
  const uid = localStorage.getItem('user_uid') || userInfo?.uid || ''
  // const [loginInfo, setLoginInfo] = useState<LoginInfo | any>()
  

  const signature = useMemo(() => {
    switch (true) {
      case channelValue?.includes('jurongyi'):
        return '聚融易'
      default:
        return '聚好借'
    }
  }, [channelValue])

  // 有钱钱包隐私协议
  const PrivacyAgreementProtocol = useMemo(() => {
    if (protocol?.includes(ProtocolListType.youqianqianbaoPrivacy)) {
      return <>
         <div className={styles.title}>有钱钱包隐私协议</div>
            <div>
              <PrivacyAgreement isResultPage />
              {
                loginInfo && <div style={{ background: '#F5F5F5' }}>
                  <p className={styles.bold}>手机号:  {phone || ''}</p>
                  <div className={styles.emptyLine}></div>
                  <p className={styles.bold}>姓名:  {loginInfo?.realName || ''}</p>
                  <div className={styles.emptyLine}></div>
                  <p className={styles.bold}>日期:  {formatTime(Date.now(), 'YYYY-MM-DD hh:mm:ss')}</p>
                </div>
              }
        </div>
        <Divider />
      </>
    }
  }, [protocol, phone, loginInfo])
  
  // 通用授权协议
  const generalAuthorizationProtocol = useMemo(() => {
    if (protocol?.includes(ProtocolListType.generalAuthorization)) {
      return <>
      <div className={styles.title}>通用授权协议</div>
            <div className={styles.agreementContent}>
              <h2 style={{ textAlign: 'center' }}>授权书</h2>
              <div className={styles.emptyLine}></div>
              <p>特别提示:</p>
              <p>
        为了保障您的合法权益，请您务必审慎阅读并充分理解本授权书的全部内容，特别是以加粗形式提示您注意的条款。您在展示给您的页面上点击/勾选 “确认”、“同意”或类似表明签约意愿文字的按钮，即表示您确认接受并签署本授权书，同意受到本授权书所有条款的约束。如您不接受本授权书部分或全部内容，请您立即停止使用本服务。
              </p>
              <div className={styles.emptyLine}></div>
              <p>特别提示:</p>
              <p className={styles.bold}>
          为了向您提供本服务，{ channelValue?.includes('kuaishou') ? '我们将收集' : `您明确授权本平台向本平台的合作机构（广州虫洞跳跃信息科技有限公司、${company}提供` }您的姓名、年龄、所在城市、申请额度、手机号、信用情况、工作年限、收入情况、房产信息、车辆信息、社保及公积金信息、商业保险信息、企业主信息、纳税信息，具体以您实际向本平台提供的为准。同时您同意并授权该合作机构接收您的上述个人信息并可将该信息提供给其为您提供贷款服务的合作方。您同意并授权该合作机构及其合作方可将您的个人信息进行收集、留存、整理、加工，用于为您提供贷款推荐或贷款服务。
              </p>
              <div className={styles.emptyLine}></div>
              <p className={styles.bold}>
        您已经充分认识到您的财产性信息对您而言是私密且重要的信息（本授权书所述的财产性信息包括您的收入情况、房产信息、车辆信息、社保及公积金信息、商业保险信息）。您已经充分理解并知悉提供和使用财产性信息的风险，这些风险包括但不限于：纳入这些信息对您的信用评级（评分）、信用报告等结果可能产生不利影响，该等信息被被授权人依法提供给第三方后被他人不当利用的风险，因您的信用状况较好而造成您被第三方推销产品或服务等打扰的风险等。
              </p>
              <div className={styles.emptyLine}></div>
              <p className={styles.bold}>
        您同意该合作机构及其合作方或其员工可通过电话、手机短信等方式与您取得联系，向您告知或发送服务相关信息及其他商业电子信息，并为保证服务质量对上述电话进行录音。您在此授权并自愿成为该合作机构的注册用户，并接受该合作机构平台上展示的注册协议及隐私政策的约束，并可通过直接登录该合作机构平台接受其提供的服务。
              </p>
              {
                uid && <>
                  <div className={styles.emptyLine}></div>
                  <p className={styles.bold}>您的登录账号:  {encryptString(phone, 3, 4) }</p>
                  <div className={styles.emptyLine}></div>
                  <p className={styles.bold}>uid:  {uid}</p>
                  <div className={styles.emptyLine}></div>
                </>
              }
            </div>
            <Divider />
    </>
   }
  }, [protocol])
  
  // 非大学生承诺函
  const noCollegeStudents = useMemo(() => {
    if (protocol?.includes(ProtocolListType.noCollegeStudents)) {
      return <>
          <div className={styles.title}>非大学生承诺函</div>
            <div className={styles.agreementContent}>
              <p>
        为客观、准确地评估本人的贷款资格及信用等级，现本人特此作出如下声明与承诺:
              </p>
              <div className={styles.emptyLine}></div>
              <p className={styles.bold}>本人提交授信申请及贷款申请时，已知晓金融机构不向在校学生提供信贷服务。本人确认本人不是在校学生，不存在隐瞒或者虚构身份申请贷款的情形，且本人拥有合法的固定收入来源。</p>
              <div className={styles.emptyLine}></div>
              <p >
        本人承诺：以上为本人真实陈述,不存在故意隐瞒或者虚假陈述，若因本人故意隐瞒或者虚假陈述造成的一切法律后果由本人承担，由此给{signature}及贷款人造成损失的，本人愿意承担赔偿责任。
              </p>
              <div className={styles.emptyLine}></div>
              <p >
        本人同意：本人已完全理解并同意上述承内容，且执行并同意本承诺函以数字电文形式存储于{signature}系统，并一经本人点击确认或勾选同意后以电子签名的方式签署，立即生效。
              </p>
              <div className={styles.emptyLine}></div>
              <p className={styles.bold}>
        特此确认。
              </p>
              <div className={styles.emptyLine}></div>
              {
                loginInfo && <>
                  <p className={styles.bold}>承诺人:  {loginInfo?.realName || ''}</p>
                  <div className={styles.emptyLine}></div>
                  <p className={styles.bold}>证件号:  {loginInfo?.identity ? encryptString(loginInfo?.identity, 4, 4) : '' }</p>
                  <div className={styles.emptyLine}></div>
                  <p className={styles.bold}>日期:  {formatTime(Date.now(), 'YYYY-MM-DD hh:mm:ss')}</p>
                </>
              }
        </div>
        <Divider />
      </>
    }
  }, [protocol])
  
  // 系统返回协议
  const systemReturnProtocol = useMemo(() => {
    if (protocol?.includes(ProtocolListType.defaultProtocol)) {
      return <>
         <div className={styles.title}>{ title}</div>
          {children}
      </>
    }
  },[children,protocol])

  return (
    <div className={styles.protocolList}>
      {
          <>
          {PrivacyAgreementProtocol}
          {generalAuthorizationProtocol}
          {noCollegeStudents}
          {systemReturnProtocol}
          </>
      }

    </div>
  )
}

export default ProtocolList
