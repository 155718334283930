/* eslint-disable camelcase */
import sensors from 'sa-sdk-javascript'
import { SensorsType } from '@/constants/sensorsBurying'
import { Storage } from '@bihu/common-js'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { useAppSelector } from '@/store/hooks'
import { selectUserInfo } from '@/store/user'
import { useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

/** 神策埋点-上报地址 */
// eslint-disable-next-line no-shadow
enum sensorsServerUrl {
  dev = 'https://qa.insight.yuanxinwallet.com/track/sa.gif?project=yx_dev&token=c9239cb3139077ca',
  prod = 'https://insight.chongdong.cc/track/sa.gif?project=yx&token=c9239cb3139077ca'
}

export const useSensors = () => {
  const server_url = process.env.REACT_APP_API_ENV === 'development' ? sensorsServerUrl.dev : sensorsServerUrl.prod
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)
  // 用户信息
  const userInfo = useAppSelector(selectUserInfo)
  const executed = sessionStorage.getItem('hasExecuted')
  const hasExecuted = useRef<string[]>(executed ? JSON.parse(executed) : [])
  const [search] = useSearchParams()

  const syncHasExecuted = () => {
    let execute = sessionStorage.getItem('hasExecuted')
    hasExecuted.current = execute ? JSON.parse(execute) : []
  }

  const addExecuted = (event: string) => {
    if (!hasExecuted.current.includes(event)) {
      hasExecuted.current.push(event)
      sessionStorage.setItem('hasExecuted', JSON.stringify(hasExecuted.current))
    }
  }

  /**
   * 神策埋点-初始化
  */
  const initSensors = () => {
    syncHasExecuted()
    if (hasExecuted.current.includes('initSensors')) {
      return
    }
    sensors.init({
      server_url,
      show_log: process.env.REACT_APP_API_ENV === 'development',
      send_type: 'image',
    })
    addExecuted('initSensors')
  }

  /**
   * 神策埋点-登录
   * @param {string} uid 用户uid
  */
  const loginSensors = (uid: string) => {
    sensors.login(uid)
  }

  /**
   * 神策埋点-访问落地页
  */
  const visitLoadingPageSensors = () => {
    syncHasExecuted()
    if (hasExecuted.current.includes(SensorsType.visit_landing_page)) {
      return
    }
    sensors.track(SensorsType.visit_landing_page, {
      ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code')
    })
    addExecuted(SensorsType.visit_landing_page)
  }

  /**
   * 神策埋点-用户点击登录
   * @param {number} phone 手机号
  */
  const userClickLoginSensors = (phone: number) => {
    syncHasExecuted()
    if (hasExecuted.current.includes(SensorsType.click_login)) {
      return
    }
    sensors.track(SensorsType.click_login, {
      ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code'),
      mobile: phone
    })
    addExecuted(SensorsType.click_login)
  }

  /**
   * 神策埋点-用户访问表单页面
   * @param {boolean} federated 是否联登
  */
  const userVisitFormPageSensors = (federated:boolean) => {
    syncHasExecuted()
    if (hasExecuted.current.includes(SensorsType.visit_form_page)) {
      return
    }
    sensors.track(SensorsType.visit_form_page, {
      ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code'),
      mobile: userInfo?.mobile || localStorage.getItem('user_phone') || '',
      h5_federated_login: federated,
    })
    addExecuted(SensorsType.visit_form_page)
  }

  /**
   * 神策埋点-用户点击提交表单
  */
  const userClickFormInfoSensors = () => {
    syncHasExecuted()
    if (hasExecuted.current.includes(SensorsType.click_submit_form_info)) {
      return
    }
    sensors.track(SensorsType.click_submit_form_info, {
      ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code'),
    })
    addExecuted(SensorsType.click_submit_form_info)
  }

  /**
   * 神策埋点-用户访问授权页面
   * @param {string} productList 匹配的产品列表
   * @param {number} productCount 匹配的产品数量
  */
  const userVisitAuthorizetionPageSensors = (productList:string, productCount:number) => {
    syncHasExecuted()
    if (hasExecuted.current.includes(SensorsType.visit_authorization_page)) {
      return
    }
    sensors.track(SensorsType.visit_authorization_page, {
      ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code'),
      mobile: userInfo?.mobile || localStorage.getItem('user_phone') || '',
      h5_matched_product_list: productList,
      h5_matched_product_count: productCount
    })
    addExecuted(SensorsType.visit_authorization_page)
  }

  /**
   * 神策埋点-用户点击授权
   * 暂时不会匹配多产品-所以选择产品列表可以用匹配的产品来代替
   * @param {string} productList 匹配的产品列表
   * @param {number} productCount 匹配的产品数量
   * @param {string} selectProduct 选择的产品列表
  */
  const userClickAuthorizetionPageSensors = (productList:string, productCount:number, selectProduct?:string) => {
    syncHasExecuted()
    if (hasExecuted.current.includes(SensorsType.click_authorize)) {
      return
    }
    sensors.track(SensorsType.click_authorize, {
      ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code'),
      mobile: userInfo?.mobile || localStorage.getItem('user_phone') || '',
      h5_matched_product_list: productList,
      ProductCount: productCount,
      h5_matched_product_selected_list: selectProduct || productList
    })
    addExecuted(SensorsType.click_authorize)
  }

  /**
   * 神策埋点-用户点击授权或访问授权页
   * @param {string} type 访问授权页或点击授权
   * @param {string} product 匹配的产品详情
   * @param {number[]} checkedProduct 多产品时勾选的产品
  */
  const sensorsVisitAuthorizePage = (type: SensorsType.visit_authorization_page | SensorsType.click_authorize, product:any, checkedProduct?: number[]) => {
    try {
      let productList = []
      let selectProductList = []
      if (product?.thirdpartyType === 7) {
        productList.push(`${product.productName}-${product.yqlWholeProcessBumpVO?.partnerProductName}`)
        selectProductList.push(`${product.productName}-${product.yqlWholeProcessBumpVO?.partnerProductName}`)
      } else {
        productList.push(product?.productName)
        selectProductList.push(product?.productName)
      }

      if (product?.mayAuthProductList?.length > 0) {
        product?.mayAuthProductList.forEach((element:any) => {
          if (element.thirdpartyType === 7) {
            productList.push(`${element.productName}-${element.partnerProductName}`)
            if (checkedProduct && checkedProduct.includes(element.id)) {
              selectProductList.push(`${element.productName}-${element.partnerProductName}`)
            }
          } else {
            productList.push(element.productName)
            if (checkedProduct && checkedProduct.includes(element.id)) {
              selectProductList.push(element.productName)
            }
          }
        })
      }
      if (type === SensorsType.visit_authorization_page) {
        userVisitAuthorizetionPageSensors(productList.join(';'), productList.length)
      } else {
        userClickAuthorizetionPageSensors(productList.join(';'), productList.length, selectProductList.join(';'))
      }
    } catch (err) {
      console.log('埋点上报出错>>>>', type, err)
    }
  }

  /**
   * 神策埋点-用户访问结果页
   * @param {boolean} matchResult 匹配结果
   * @param {boolean} monetization 是否有后端变现
  */
  const visitResultPageSensors = (matchResult: boolean, monetization: boolean) => {
    syncHasExecuted()
    if (hasExecuted.current.includes(SensorsType.visit_result_page)) {
      return
    }
    sensors.track(SensorsType.visit_result_page, {
      ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code'),
      mobile: userInfo?.mobile || localStorage.getItem('user_phone') || '',
      h5_match_result: matchResult,
      result_page_monetization: monetization
    })
    addExecuted(SensorsType.visit_result_page)
  }

  /**
   * 神策埋点-用户点击下载App
  */
  const downloadAppSensors = () => {
    syncHasExecuted()
    if (hasExecuted.current.includes(SensorsType.click_download_app)) {
      return
    }
    sensors.track(SensorsType.click_download_app, {
      ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code'),
      mobile: userInfo?.mobile || localStorage.getItem('user_phone') || ''
    })
    addExecuted(SensorsType.click_download_app)
  }

  /**
   * 神策埋点-用户点击后端H5产品
   * @param {string} productName 产品名称
  */
  const clickH5ProductSensors = (productName: string) => {
    sensors.track(SensorsType.click_backend_h5_product, {
      ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code'),
      mobile: userInfo?.mobile || localStorage.getItem('user_phone') || '',
      clicked_h5_product: productName
    })
  }

  return {
    initSensors,
    loginSensors,
    visitLoadingPageSensors,
    userClickLoginSensors,
    userVisitFormPageSensors,
    userClickFormInfoSensors,
    userVisitAuthorizetionPageSensors,
    userClickAuthorizetionPageSensors,
    visitResultPageSensors,
    sensorsVisitAuthorizePage,
    downloadAppSensors,
    clickH5ProductSensors
  }
}