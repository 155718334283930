/* eslint-disable jsx-a11y/alt-text */
// skin18皮肤
/* eslint-disable camelcase */
/* eslint-disable no-magic-numbers */
import React, { useState, useEffect, useMemo } from 'react'
import styles from './skin18.module.scss'
import classNames from 'classnames'
import { Divider, Dropdown, Toast } from 'antd-mobile'
import { parseNum, isPhone, Storage } from '@bihu/common-js'
import { TOKEN, ENTER_AGREEMENT } from '@/constants/storage'
import NumberInput from '@/components/number-input/number-input'
import activityApi from '@/apis/activity'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { phoneList } from './assets/assets'
import sensors from 'sa-sdk-javascript'
import { SensorsType } from '@/constants/sensorsBurying'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { CompanyType } from '@/constants/common'

interface Props {
  channelDetail: any // 渠道详情
  register: (code: string, phone: string, skinNum:number) => void
  loginAfterLocation:() => void
}

interface cordItem {
  time: number,
  phone: string,
  amount: number,
}

interface ArrType {
  label: string,
  value: number
}

const Skin18: React.FC<Props> = props => {
  const navigate = useNavigate()
  const { channelValue } = useSelector((state: RootState) => state.channel)
  const { channelDetail, register, loginAfterLocation } = props

  const [search] = useSearchParams()

  // 渠道编码
  const [adChannelCode, setAdChannelCode] = useState('')

  useEffect(() => {
    const hasToken = Storage.get(TOKEN)
    const code = search.get('adChannelCode')
    randomUserCards()
    if (code) {
      setAdChannelCode(code)
      localStorage.setItem('ad_channel_code', code)
      // 是否需要获取定位
      if (hasToken && channelDetail.location) {
        loginAfterLocation()
      }
    }

    // 测试环境随机生成手机号
    // if (getEnvironment() !== 'prod') {
    //   const radomPhoneNum = generateRandomPhoneNumber()
    //   localStorage.setItem('user_phone', radomPhoneNum)
    //   setPhone(radomPhoneNum)
    // }
  }, [])

  // 手机号码
  const [phone, setPhone] = useState('')
  // 验证码
  const [code, setCode] = useState('')

  // 选择协议
  const [checked, setChecked] = useState(false)
  // 是否抖动
  const [isShake, setIsShake] = useState(false)
  // 切换选中协议
  const checkedTaggle = () => {
    setChecked(!checked)
  }

  // 借款金额
  const [amount, setAmount] = useState<string>(parseNum(50000))

  const amountRecommendArr:ArrType[] = [
    {
      label: '50000',
      value: 50000
    },
    {
      label: '100000',
      value: 100000
    },
    {
      label: '全部借出',
      value: 200000
    },
  ]

  const [timePeriod, setTimePeriod] = useState({
    label: '12个月',
    value: 2
  })
  const [moneyWay, setMoneyWay] = useState({
    label: '日常消费',
    value: 1
  })

  //借多久
  const TimePeriod: ArrType[] = [
    {
      label: '6个月',
      value: 1
    },
    {
      label: '12个月',
      value: 2
    },
    {
      label: '24个月',
      value: 3
    },
    {
      label: '36个月',
      value: 4
    },
  ]

  //用途
  const MoneyWay: ArrType[] = [
    {
      label: '日常消费',
      value: 1
    },
    {
      label: '装修',
      value: 2
    },
    {
      label: '医疗',
      value: 3
    },
    {
      label: '教育',
      value: 4
    },
    {
      label: '其他',
      value: 5
    },
  ]

  // 跳转页面
  const openView = (path: string) => {
    Storage.set(ENTER_AGREEMENT, true)
    navigate(`/${path}-agreement?isShowHeader=true`)
  }

  // 定时器对象
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>()
  // 当前倒计时剩余时间
  const [time, setTime] = useState(0)

  // 倒计时时间
  const countDownTime = 60
  // 倒计时函数
  const countDownFn = () => {
    setTime(countDownTime)
  }

  useEffect(() => {
    if (time > 0) {
      let t = setTimeout(() => {
        setTime(time - 1)
      }, 1000)
      setTimer(t)
    } else {
      timer && clearTimeout(timer)
      setTimer(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time])

  // 获取验证码
  const getCode = async() => {
    if (!phone) {
      return Toast.show('请输入手机号码')
    }
    if (!isPhone(phone)) {
      return Toast.show('手机号码格式有误')
    }
    countDownFn()

    try {
      await activityApi.smsSend({
        mobile: +phone,
        channelCode: adChannelCode
      })

      // 前端神策埋点 - 用户获取验证码
      sensors.track(SensorsType.cilck_get_verification_code, {
        ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code'),
        mobile: +phone
      })
    } catch (error) {
      timer && clearTimeout(timer)
    }

    // Toast.show('获取验证码成功')
  }

  const confirm = () => {
    if (!phone) {
      return Toast.show('请输入手机号码')
    }
    if (!isPhone(phone)) {
      return Toast.show('手机号码格式有误')
    }
    if (!checked) {
      setIsShake(true)
      setTimeout(() => {
        setIsShake(false)
      }, 100)
      return Toast.show('请勾选注册、隐私及个人信息共享授权协议')
    }
    if (!code) {
      return Toast.show('验证码不能为空，请重新输入')
    }
    if (code?.length < 6) {
      return Toast.show('验证码输入有误，请重新输入')
    }
    register(code, phone, 18)
    // setShowPopup('true')
  }

  const [cords, setCords] = useState<cordItem[]>([])

  const randomUserCards = () => {
    // 随机获取数组长度中的一个元素
    const getRandomElement = (arr:Array<number|string>) => arr[Math.floor(Math.random() * arr.length)]
    // 生成随机数范围
    const randomNum = (minNum:number, maxNum:number) => {
      return parseInt((Math.random() * (maxNum - minNum + 1) + minNum).toString(), 10)
    }
    const getRandomPhone = () => `${getRandomElement(phoneList)}****${randomNum(1000, 9999)}`
    const getRandomAmount = () => {
      const randomAmount = randomNum(3000, 200000)
      return randomAmount < 10000 ? Math.floor(randomAmount / 1000) * 1000 : Math.floor(randomAmount / 10000) * 10000
    }
    const records:cordItem[] = cords
    for (let i = 0; i < 10; i++) {
      const record = {
        time: randomNum(3, 15),
        phone: getRandomPhone(),
        amount: getRandomAmount(),
      }
      records.push(record)
    }
    setCords([...records])
  }
  // const [open, setOpen] = useState(true)

  const bottomSubject = useMemo(() => {
    switch (true) {
      case channelValue.includes('jfxd'):
        return `${CompanyType.JFXD_COMPANY.recordNumber} | ${CompanyType.JFXD_COMPANY.company}`
      case channelValue.includes('noCompanyName'):
        return CompanyType.YQQB_COMPANY.recordNumber
      case channelValue.includes('zhonganXy'):
        return `${CompanyType.ZAXY_COMPANY.recordNumber} | ${CompanyType.ZAXY_COMPANY.company}`
      default:
        return `${CompanyType.YQQB_COMPANY.recordNumber} | ${CompanyType.YQQB_COMPANY.company}`
    }
  }, [channelValue])
  return (
    <div className={styles.skin18}>
      <div className={styles.scrollcontainer}>
        <div className={styles.scrollcontent}>
          {cords.map((item: cordItem) =>
            <div key={item.amount} className={styles.scrollRow}>
              <img src={require('@imgs/register/icon-sound.png')} style={{
                marginRight: '5px',
                height: '12px',
                width: '12px',
                padding: '1px 0 0 2px'
              }}/>{item.time}分钟前，{item.phone}成功下款 {item.amount} 元</div>
          )}
        </div>
      </div>
      <header className={styles.header}>
        <div className={styles.inputWrapper}>
          <div className={styles.inputWrapperTitle}>
            <span >借款金额（元）</span>
          </div>
          <div className={styles.inputBox}>￥{amount}</div>
          <div className={styles.inputBoxText}>预计最高可借 200,000</div>
          <div className={styles.moneyOptions}>
            {
              amountRecommendArr.map((item:ArrType) => {
                return (
                  <div key={item.value} className={classNames(styles.moneyItem, amount === parseNum(item.value) && styles.moneyItemActive)} onClick={() => setAmount(parseNum(item.value))}>{ item.label }</div>
                )
              })
            }
          </div>
        </div>
        <div className={styles.headerBottomText}>年化利率9%-24% (单利) 根据审核结果不同额度不同，以审核为准</div>
      </header>
      <div className={styles.checkBox}>
        <div className={styles.chooseBox}>
          <div className={styles.chooseLeft}>借多久</div>
          <div className={styles.chooseRight}>
            <Select
              styles={{
                control: (baseStyles:any) => ({
                  ...baseStyles,
                  border: 0,
                  textAlign: 'right',
                  color: 'transparent',
                  textShadow: '0 0 0 #000'
                }),
              }}
              // menuIsOpen={open} //调试menu时开启
              options={TimePeriod}
              value={timePeriod}
              isSearchable={false}
              onChange={(e: any) => {
                setTimePeriod(e)
              }}
              autoFocus
              className={styles.selectBox} classNamePrefix="selectBox"></Select>
          </div>
        </div>
        <div className={styles.chooseBox}>
          <div className={styles.chooseLeft}>资金用途</div>
          <div className={styles.chooseRight}>
            <Select
              styles={{
                control: (baseStyles:any) => ({
                  ...baseStyles,
                  border: 0,
                  textAlign: 'right',
                  color: 'transparent',
                  textShadow: '0 0 0 #000'
                }),
              }}
              options={MoneyWay}
              value={moneyWay}
              onChange={setMoneyWay}
              isSearchable={false}
              className={styles.selectBox} classNamePrefix="selectBox"></Select>
          </div>
        </div>
        <div className={styles.bottomBody}>
          <div className={styles.phoneBox}>
            <img style={{
              width: '20px'
            }} src={require('@imgs/register/mobile.png')}></img>
            <Divider direction="vertical"></Divider>
            <NumberInput
              placeholder={ phone === '' || phone === undefined ? '凭手机号领取额度 (已加密)' : '请输入手机号'}
              value={phone}
              numberFormat={{
                decimal: false,
                negative: false,
                leadingZero: false,
                maxLength: 11
              }}
              onChange={val => {
                setPhone(val)
              }}
            />
          </div>
          {
            phone.length === 11 && <div className={styles.phoneBox}>
              <NumberInput
                placeholder="请输入验证码"
                value={code}
                numberFormat={{
                  decimal: false,
                  negative: false,
                  leadingZero: false,
                  maxLength: 6
                }}
                onChange={val => {
                  setCode(val)
                }}
              />
              <Divider direction="vertical"></Divider>
              {
                time ? <span className={styles.codeTime}>剩余 {time}s</span>
                  : <span onClick={getCode} className={styles.getCode}>获取验证码</span>
              }

            </div>
          }
          <div className={styles.getCms} onClick={confirm}>领取额度</div>
          <div className={classNames(styles.agreement, isShake && styles.animation)}>
            <i className={classNames(styles.checked, checked && styles.active)} onClick={checkedTaggle}></i>
              阅读并同意
            <span
              className={styles.primary}
              onClick={() => {
                openView('register')
              }}
            >
              《用户注册服务协议》
            </span>
            、
            <span className={styles.primary} onClick={() => {
              openView('privacy')
            }}>《隐私政策》</span>
            和
            <span className={styles.primary} onClick={() => {
              openView('information')
            }}>《个人信息共享授权协议》</span>
          </div>
        </div>
      </div>
      <div className={styles.messageBox}>
        <div className={styles.messageBoxTitle}>快速申请 3步完成</div>
        <div className={styles.messageBoxText}>实际放款额度及利率，以最终审批为准</div>
        <div className={styles.messageBoxImgBox}>
          <div>
            <img src={require('@imgs/register/skin18/lock.png')}></img>
            <div>加密注册</div>
          </div>
          <div>
            <img src={require('@imgs/register/skin18/file-text.png')}></img>
            <div>完善资料</div>
          </div>
          <div>
            <img src={require('@imgs/register/skin18/money-collect.png')}></img>
            <div>审批放款</div>
          </div>
        </div>
      </div>
      <div className={styles.tipBox}>
        <div className={styles.tip}>本平台是信息服务平台，不提供放贷业务</div>
        <div className={styles.tip}>平台向您展示的贷款产品或贷款咨询服务是由贷款服务机构</div>
        <div className={styles.tip}>(泛指为您提供贷款咨询服务或贷款发放服务的机构)</div>
        <div className={styles.tip}>或贷款中介咨询机构提供</div>
        <div className={styles.tip}>平台展示的贷款产品年利率范围9%-24%</div>
        <div className={styles.tip}>具体利率以第三方实际放款结果为准</div>
        <div className={styles.tip}>您最终获取的贷款额度、利率、放款时间</div>
        <div className={styles.tip}>以第三方机构实际审批结果为准</div>
        <div className={styles.tip}>温馨提示:请根据个人能力合理贷款，理性消费，避免逾期</div>
        <div className={styles.tip}>请不要相信任何要求您支付费用的信息、邮件、电话等不实信息</div>
        {channelValue?.includes('xinyonghuaRegisterPage') && <div className={styles.tip}>贷款资金由正规金融持牌放款机构提供</div>}
        {channelValue?.includes('registerTips') && <div className={styles.tip}>资金来源：{ CompanyType.ZAXY_COMPANY.company}</div>}
        {channelValue?.includes('registerTips') && <div className={styles.tip}>贷款有风险 借款需谨慎</div>}
        <div className={styles.tip}>
          {bottomSubject}
        </div>
        <div className={styles.tip}>客服电话：4006686025</div>
      </div>
    </div>
  )
}

export default Skin18

