/* eslint-disable no-nested-ternary */
/* eslint-disable no-magic-numbers */
import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react'
import { Button } from 'antd-mobile'
import styles from './360-white-list-city.module.scss'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import Icon from '@/components/icon/icon'
interface Props {
  onChange: () => void, // 改变事件
}

const WhiteListCity: React.FC<Props> = props => {
  const { channelValue } = useSelector((state: RootState) => state.channel)
  const { onChange } = props

  const youqianLogoText = useMemo(() => {
    switch (true) {
      case channelValue?.includes('juhaojie'):
        return <Icon type="jufu-haier-text-logo" className={styles.youqianTextLogoSvg}></Icon>
      case channelValue?.includes('jurongyi'):
        return <Icon type="jufu-jurongyi-text-logo" className={styles.youqianTextLogoSvg}></Icon>
      case channelValue?.includes('jufu'):
        return <img className={styles.youqianLogoText} src={require('@imgs/jufu/title.png')} alt="youqian-text" />
      default:
        return <img className={styles.youqianLogoText} src={require('@imgs/youqianqianbao-text.png')} alt="youqian-text" />
    }
  }, [channelValue])

  const youqianLogo = useMemo(() => {
    switch (true) {
      case channelValue?.includes('juhaojie'):
        return <img className={styles.logo} style={{ borderRadius: '5px' }} src={require('@imgs/jufu/jufuLogo.jpg')} alt="logo" />
      case channelValue?.includes('jurongyi'):
        return <img className={styles.logo} style={{ borderRadius: '5px' }} src={require('@imgs/jufu/jurongyiLogo.png')} alt="logo" />
      default:
        return <img className={styles.logo} src={require('@imgs/youqianqianbao-logo.png')} alt="logo" />
    }
  }, [channelValue])

  const headerBackground = useMemo(() => {
    switch (true) {
      case channelValue?.includes('huanbeitheme'):
        return `url(${require('@imgs/mint-green/result_background.png')})`
      case channelValue.includes('shallowBlue'):
        return `url(${require('@imgs/shallow-blue/result_background.png')})`
      // case channelValue.includes('halo'):
      //   return `url(${require('@imgs/middle-blue/result_background.png')})`
      case channelValue.includes('jufu'):
        return `url(${require('@imgs/jufu/result-backgroud.png')})`
      default:
        return `url(${require('@imgs/ios-skin/head-bg.png')})`
    }
  }, [channelValue])

  return (
    <div className={styles.iosSkin}>
      <div className={styles.main} style={{
        backgroundImage: headerBackground
      }}>
        <div className={styles.logoBox}>
          {youqianLogo}
          <div className={styles.logoBoxRight}>
            {youqianLogoText}
            {/* <img className={styles.youqianLogoText} src={youqianLogoText} alt="youqian-text" /> */}
            <img className={styles.logoText2} src={require('@imgs/register/new-theme/logo-subtitle.png')} alt="正规品牌 真实可信" />
          </div>
        </div>
        <div className={styles.applyResultBox}>
          <img className={styles.applyResultBoxIcon} src={require('@imgs/ios-skin/success.png')} alt="success" />
          <div className={styles.applyResultBoxRight}>
            <div className={styles.applyResultBoxText1}>您已注册成功</div>
            <div className={styles.applyResultBoxText2}>请下载APP进行实名认证，获取额度</div>
          </div>
        </div>
        <div className={classNames(styles.containers, styles.passBox)}>
          <img className={styles.containersBg} src={require('@imgs/form-result/bg.png')} alt="bg" />
          <div className={styles.textBox}>
            <div className={styles.textBoxTitle}>最高可借(元)</div>
            <div className={styles.textBoxPrice}>50,000</div>
          </div>
        </div>
        <div className={styles.btnBox}>
          <Button block className={styles.btn} shape="rounded" color="primary" onClick={onChange}>立即下载APP</Button>
        </div>
      </div>
    </div>
  )
}

export default WhiteListCity
