/* eslint-disable no-magic-numbers */
// API转H5产品皮肤
import React, { useMemo, useState } from 'react'
import styles from './institution-list.module.scss'
import { Popup, Checkbox, Image } from 'antd-mobile'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { CheckCircleFill } from 'antd-mobile-icons'
import Icon from '@/components/icon/icon'


interface Props {
  rqResult: any,
  onCheckedChange: (id: number, value: boolean) => void
  checkInstitution?: number[] //多产品当前勾选的产品id数组
  isPopupType?:boolean
}

const InstitutionList: React.FC<Props> = ({ rqResult, onCheckedChange, checkInstitution, isPopupType }) => {
  const { channelValue } = useSelector((state: RootState) => state.channel)

  // 是否显示弹出层
  const [monthVisible, setMonthVisible] = useState(false)
  // 当前选中月份
  // eslint-disable-next-line no-magic-numbers
  const [currentMonth, setCurrentMonth] = useState<number>(12)

  // 月份数组
  const monthArr = [
    {
      key: 1,
      num: 3,
    },
    {
      key: 2,
      num: 6,
    },
    {
      key: 3,
      num: 9,
    },
    {
      key: 4,
      num: 12,
    },
  ]

  // 选择月份
  const selectMonth = (num: number) => {
    setCurrentMonth(num)
    setMonthVisible(false)
  }

  const institutionListBg = useMemo(() => {
    switch (true) {
      case channelValue.includes('jufu'):
        return `url(${require('@imgs/jufu/product-list-bg.png')})`
      case channelValue.includes('shallowBlue'):
        return `url(${require('@imgs/shallow-blue/shallowBlue-product-list-bg.png')})`
      default:
        return `url(${require('@imgs/information/card-bg.png')})`
    }
  }, [channelValue])

  return (
    <div className={styles.institutionList}>
      {
        // 这里添加的逻辑是判断是否为多产品，如果为多产品则展示多产品列表，如果不是就往下走逻辑
        // eslint-disable-next-line no-nested-ternary
        <div className={styles.institutionListBox} style={{ backgroundImage: institutionListBg }}>
          <div className={styles.yqlWholeProcessBoxHeader}>
            <div className={styles.yqlWholeProcessBoxHeaderLeft}>
              <div className={styles.welcome}>
                {/* {
                  !isPopupType && channelValue?.includes('jufu') ? <img className={styles.yqlWholeProcessBoxHeaderIcon} src={} /> : <img className={styles.yqlWholeProcessBoxHeaderIcon} src={require('@imgs/information/checked2.png')} />
                } */}
                <CheckCircleFill fontSize={20} className={ styles.yqlWholeProcessBoxHeaderIcon} />
                <div className={styles.yqlWholeProcessBoxHeaderTitle}>恭喜您，贷款匹配成功</div>
              </div>
              {
                isPopupType && <div className={styles.seconedTitle}>
                  {/* <img src={require('@/assets/imgs/form-result/new-success.png')} className={styles.iconSuccess} alt="youqianqianbao-new-success" /> */}
                  <Icon type="matchSuccessIcon" className={styles.iconSuccess}></Icon>
                  <span>严选正规机构100+，为您匹配低息借款方案</span>
                </div>
              }
            </div>
            <div className={styles.yqlWholeProcessBoxHeaderRight}>
              {
                rqResult.mayAuthProductList?.length > 0 && <>
                  <span style={{ marginBottom: '5px' }}>同时申请放款率</span>
                  {
                    channelValue?.includes('jufu')
                      ? <img src={require('@/assets/imgs/jufu/persent.png')} style={{ width: '70px' }} alt="youqianqianbao-persent" />
                      : <img src={require('@/assets/imgs/information/persent.png')} style={{ width: '70px' }} alt="youqianqianbao-persent" />
                  }
                </>
              }
            </div>
          </div>
          <div className={styles.splitLine}></div>
          <div className={styles.multipleProcessBoxContent}>
            <div className={styles.multipleProcessTitle}>
                贷款服务平台及机构
            </div>
            <div className={styles.multipleProcessContentItem}>
              <div className={styles.multipleProcessContentItemLeft}>
                <div>
                  {
                    rqResult.thirdpartyType !== 7 || !rqResult.yqlWholeProcessBumpVO?.partnerLogo ? <>
                      <img className={styles.contentItemIcon} src={require('@imgs/information/loan-platform.png')} />
                    </> : <>
                      <Image className={styles.contentItemIcon} fit="cover" style={{
                        marginRight: '10px',
                        borderRadius: 6
                      }} src={rqResult.yqlWholeProcessBumpVO?.partnerLogo} fallback={<img className={styles.contentItemIcon} src={require('@imgs/information/loan-institutions.png')} />}></Image>
                      {/* <img className={styles.contentItemIcon} src={rqResult.yqlWholeProcessBumpVO?.partnerLogo} /> */}
                    </>
                  }
                </div>
                {
                  channelValue?.includes('noListMainBody')
                    ? <div className={styles.multipleProcessContentItemInfo}>
                      <span>
                        { rqResult.thirdpartyType === 7 ? rqResult.productName : '有钱钱包' }
                      </span>
                      <div>
                        {
                          rqResult.thirdpartyType === 7
                            ? <>{rqResult.yqlWholeProcessBumpVO?.partnerProductName ?? ''}</>
                            : <>{rqResult.productName ?? ''}</>
                        }
                      </div>
                    </div>
                    : <div className={styles.multipleProductInfo}>
                      <div className={styles.multipleProductInfoTop}>
                        <span className={styles.productName}>
                          {rqResult.thirdpartyType === 7 ? rqResult.yqlWholeProcessBumpVO?.partnerProductName ?? '' : rqResult.productName ?? ''}
                        </span>
                        { rqResult.thirdpartyType === 7
                          && <div className={styles.platformName}>服务平台：{ rqResult.productName}</div>
                        }
                      </div>
                      <span className={styles.partnerCompanyName}>
                        {rqResult.thirdpartyType === 7 ? rqResult.yqlWholeProcessBumpVO?.partnerCompanyName ?? '' : rqResult.productCompany ?? ''}
                      </span>
                    </div>
                }
              </div>
              <div className={styles.multipleProcessContentItemRight}>
                <Checkbox checked={true} disabled={true} className={styles.checkBoxOne}></Checkbox>
              </div>
            </div>
            {
              rqResult.mayAuthProductList?.length > 0 && rqResult.mayAuthProductList.map((product: any) => {
                return <div key={product.id} className={styles.multipleProcessContentItem}>
                  <div className={styles.multipleProcessContentItemLeft}>
                    <div>
                      {
                        product.thirdpartyType !== 7 || !product.partnerLogo ? <>
                          <img className={styles.contentItemIcon} src={require('@imgs/information/loan-platform.png')} />
                        </> : <>
                          <Image className={styles.contentItemIcon} fit="cover" style={{
                            marginRight: '10px',
                            borderRadius: 6
                          }} src={product.partnerLogo} fallback={<img className={styles.contentItemIcon} src={require('@imgs/information/loan-institutions.png')} />}></Image>
                        </>
                      }
                    </div>
                    {
                      channelValue?.includes('noListMainBody')
                        ? <div className={styles.multipleProcessContentItemInfo}>
                          <span>
                            { product.thirdpartyType === 7 ? product.productName : '有钱钱包' }
                          </span>
                          <div>
                            {product.thirdpartyType === 7
                              ? <>{product.partnerProductName ?? ''}</>
                              : <>{product.productName}</>
                            }
                          </div>
                        </div>
                        : <div className={styles.multipleProductInfo}>
                          <div className={styles.multipleProductInfoTop}>
                            <span className={styles.productName}>
                              {product.thirdpartyType === 7 ? product.partnerProductName ?? '' : product.productName ?? ''}
                            </span>
                            { product.thirdpartyType === 7
                          && <div className={styles.platformName}>服务平台：{ product.productName}</div>
                            }
                          </div>
                          <span className={styles.partnerCompanyName}>
                            {product.thirdpartyType === 7 ? product.partnerCompanyName ?? '' : product.productCompany ?? ''}
                          </span>
                        </div>
                    }
                  </div>
                  <div className={styles.multipleProcessContentItemRight}>
                    <Checkbox checked={checkInstitution?.includes(product.id)} onChange={e => onCheckedChange(product.id, e)} className={styles.checkBoxOne}></Checkbox>
                  </div>
                </div>
              })
            }
          </div>
        </div>
      }
      <Popup
        visible={monthVisible}
        onMaskClick={() => {
          setMonthVisible(false)
        }}
      >
        <ul className={styles.monthBox}>
          {
            monthArr.map(item => {
              return <li
                key={item.key}
                className={classNames(styles.row, item.num === currentMonth && styles.active)}
                onClick={() => selectMonth(item.num)}
              >
                {item.num} 个月
              </li>
            })
          }
        </ul>
      </Popup>
    </div>
  )
}

export default InstitutionList
