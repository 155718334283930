/* eslint-disable no-magic-numbers */
import activityApi from '@/apis/activity'
import productApi from '@/apis/product'
import { EntranceType } from '@/types/apis/index'
import Event from '@/event'
import { APPLY_STATUS_MODAL } from '@/event/constant'
import { Storage } from '@bihu/common-js'
import { ROUTE_CODE_MAP } from '@/constants/storage'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Tree = Record<string, any>

interface Options<T> {

  /** 子节点数组属性名 */
  childrenProp?: string

  /** 父节点 */
  parent?: T | null;
}

interface JSONPRequestOptions {
  timeout?: number; // 超时时间，单位为毫秒，默认 5000
  callbackParam?: string; // 回调函数名称参数，默认 'callback'
  params?: Record<string, string | number>; // 传入的参数
}

// 递归树结构的数据，并对每一个节点执行 callback
export const traverseTree = <T extends Tree>(tree: T[], callback: (treeItem: T, parentItem: T | null) => void, {
  parent = null,
  childrenProp = 'children'
}: Options<T> = {}) => {
  tree.forEach(treeItem => {
    callback(treeItem, parent)
    const children = treeItem[childrenProp] as T[]
    if (children && children.length) {
      traverseTree(children, callback, {
        parent: treeItem,
        childrenProp
      })
    }
  })
}

// 跳转到内嵌页面
export const jumpInlinePage = async(productId: number, entranceType:EntranceType | undefined, productName?: string) => {
  const res = await productApi.apply({
    productId, //产品id
    entranceType, // 入口类型，1-首页中插，2-首页弹窗、3-开屏、4-顶部推荐、5-首页列表、6-产品列表 7-随机推荐
    appH5: 4 //有钱助手
  })

  window.location.href = `/inline-page?url=${encodeURIComponent(JSON.stringify(res.thirdpartyTargetUrl))}${productName ? `&productName=${productName}` : ''}`
}

// 上报产品曝光
export const reportProductExposure = (productId: number, entranceType:EntranceType, appH5?:number) => {
  activityApi.reportProductExposure({
    productId,
    entranceType,
    appH5,
  })
}
// 生成随机数范围
export const randomNum = (minNum:number, maxNum:number) => {
  return parseInt((Math.random() * (maxNum - minNum + 1) + minNum).toString(), 10)
}

/**
 * 通过身份证号码查出生日期
 * @param {string} idCard 身份证号码
 * @returns {string} birthday
 */
export function getBirthday(idCard:string):string {
  let birthday = ''
  if (idCard != null && idCard !== '') {
    if (idCard.length === 15) {
      birthday = `19${idCard.substring(6, 12)}`
    } else if (idCard.length === 18) {
      birthday = idCard.substring(6, 14)
    }

    birthday = birthday.replace(/(.{4})(.{2})/, '$1-$2-')
  }

  return birthday
}

/**
 * 通过身份证判断性别
 * @param {string} idCard 身份证号码
 * @returns {string} birthday
 */
export function getSex(idCard: string):string {
  let sexStr = ''
  if (parseInt(idCard.slice(-2, -1)) % 2 === 1) {
    sexStr = '1'
  } else {
    sexStr = '2'
  }
  return sexStr
}

/**
 * 通过身份证号计算年龄
 * @param {string} idCard 身份证号码
 * @returns {number} birthday
 */
export function getAge(idCard:string):number {
  let len = (`${idCard}`).length
  if (len === 0) {
    return 0
  } else if (len !== 15 && len !== 18) {
    //身份证号码只能为15位或18位其它不合法
    return 0
  }
  let strBirthday = ''
  if (len === 18) {
    //处理18位的身份证号码从号码中得到生日和性别代码
    strBirthday
      = `${idCard.substring(6, 10)
      }/${
        idCard.substring(10, 12)
      }/${
        idCard.substring(12, 14)}`
  }
  if (len === 15) {
    strBirthday
      = `19${
        idCard.substring(6, 8)
      }/${
        idCard.substring(8, 10)
      }/${
        idCard.substring(10, 12)}`
  }
  //时间字符串里，必须是“/”
  let birthDate = new Date(strBirthday)
  let nowDateTime = new Date()
  let age = nowDateTime.getFullYear() - birthDate.getFullYear()
  //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
  if (
    nowDateTime.getMonth() < birthDate.getMonth()
    || (nowDateTime.getMonth() === birthDate.getMonth()
      && nowDateTime.getDate() < birthDate.getDate())
  ) {
    age--
  }
  return age
}

/**
 * 加密字符串的部分内容，将指定范围内的字符替换为 '*'
 * @param {string} input - 需要加密的输入字符串
 * @param {number} start - 开始加密的起始索引（从0开始）
 * @param {number} end - 从字符串末尾开始倒数多少个字符停止加密
 * @returns {string} - 加密后的字符串
 */

export function encryptString(input: string, start: number, end: number): string {
  const { length } = input
  // 确保结束索引有效
  if (end > length) {
    return input
  }
  const actualEnd = length - end
  if (start < 0 || actualEnd <= start) {
    return input
  }
  // 构建加密后的字符串
  const encrypted = input
    .split('')
    .map((char, index) => (index >= start && index < actualEnd ? '*' : char))
    .join('')

  return encrypted
}

/**
 * JsonP请求封装，
 * @param {string} url - 请求路径
 * @param {JSONPRequestOptions} options - 请求参数和配置
 * @returns {any} - 请求数据返回
 */

export const jsonpRequest = <T>(
  url: string,
  options: JSONPRequestOptions = {}
): Promise<T> => {
  const { timeout = 5000, callbackParam = 'callback', params = {} } = options

  return new Promise((resolve, reject) => {
    const callbackName = params.callback || `jsonp_callback_${Date.now()}`
    const script = document.createElement('script')

    // 将参数对象转换为字符串类型
    const queryString = new URLSearchParams(
      Object.entries({
        ...params,
        [callbackParam]: callbackName, // 添加回调函数名
      }).map(([key, value]) => [key, String(value)]) // 转换为字符串类型
    ).toString()

    // 拼接最终的请求 URL
    const jsonpUrl = `${url}${url.includes('?') ? '&' : '?'}${queryString}`;

    // 创建一个全局回调函数，处理成功响应
    (window as any)[callbackName] = (data: T) => {
      resolve(data)
      // 请求完成后清理脚本和全局回调
      document.body.removeChild(script)
      delete (window as any)[callbackName]
    }

    // 设置超时处理
    const timeoutId = setTimeout(() => {
      reject(new Error('JSONP request timed out'))
      // 清理脚本和全局回调
      document.body.removeChild(script)
      delete (window as any)[callbackName]
    }, timeout)

    // 处理脚本加载错误
    script.onerror = () => {
      clearTimeout(timeoutId)
      reject(new Error('JSONP request failed'))
      document.body.removeChild(script)
      delete (window as any)[callbackName]
    }

    // 向 DOM 中添加脚本
    script.src = jsonpUrl
    document.body.appendChild(script)
  })
}


/**
 * 判断手机号
 * @param {string} sUserAgent - 用户使用设备信息
 * @returns {string} - 用户使用设备
 */

export const judgeBrand = (sUserAgent: string): string => {
  const isIphone = sUserAgent.match(/iphone/i) !== null
  const isHuawei = sUserAgent.match(/huawei/i) !== null
  const isHonor = sUserAgent.match(/honor/i) !== null
  const isOppo = sUserAgent.match(/oppo/i) !== null
  const isOppoR15 = sUserAgent.match(/pacm00/i) !== null
  const isVivo = sUserAgent.match(/vivo/i) !== null
  const isXiaomi = sUserAgent.match(/mi\s/i) !== null
  const isXiaomi2s = sUserAgent.match(/mix\s/i) !== null
  const isRedmi = sUserAgent.match(/redmi/i) !== null
  const isSamsung = sUserAgent.match(/sm-/i) !== null

  if (isIphone) {
    return 'iphone'
  } else if (isHuawei || isHonor) {
    return 'huawei'
  } else if (isOppo || isOppoR15) {
    return 'oppo'
  } else if (isVivo) {
    return 'vivo'
  } else if (isXiaomi || isRedmi || isXiaomi2s) {
    return 'xiaomi'
  } else if (isSamsung) {
    return 'samsung'
  } else {
    return 'default'
  }
}

// 渠道码有效期
const ROUTE_CODE_MAP_EXPIRE = 24 * 60 * 60 * 1000 // 有效期 24 小时
interface RouteCodeMapItem {
  channelCode: string,
  expire: number,
}

// 获取路由码缓存
export const getChannelCodeFromStorage = (routeCode: string) => {
  const routeCodeMap = Storage.get(ROUTE_CODE_MAP, 'Object', {})
  // 无缓存
  if (!routeCodeMap) {
    return ''
  }

  const item: RouteCodeMapItem | undefined = routeCodeMap[routeCode]
  // 无渠道码
  if (!item) {
    return ''
  }

  // 渠道码过期
  if ((Date.now() - item.expire) > ROUTE_CODE_MAP_EXPIRE) {
    return ''
  }

  return item.channelCode
}

// 存储路由码至 LocalStorage
export const saveRouteCodeMap = (routeCode: string, channelCode: string) => {
  const routeCodeMap = Storage.get(ROUTE_CODE_MAP, 'Object', {})
  const item = {
    channelCode,
    expire: Date.now() + ROUTE_CODE_MAP_EXPIRE,
  }
  routeCodeMap[routeCode] = item
  Storage.set(ROUTE_CODE_MAP, routeCodeMap)
}